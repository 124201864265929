import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useRef } from 'react';

import { getUser as fetchUser } from 'api';
import { consoleDev, getCurrentAuthCookies } from 'helpers';
import { logError } from 'helpers/logError';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { refreshToken as refresh, setToken } from 'redux/actions/token';
import { setUi } from 'redux/actions/ui';
import { getUser, logoutUser, replaceUser } from 'redux/actions/user';
import { ProtonJwt } from 'types';

const VALIDATE_PERIOD_MS = 30 * 1000; // 30 seconds
const VALIDATE_THRESHOLD_SECONDS = 60 * 30; // 30 minutes

function useUpdatingRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
}

function useTokenValidator() {
  const dispatch = useAppDispatch();
  const jwtValidationFinished = useAppSelector(state => state.ui.jwtValidationFinished);
  const user = useAppSelector(state => state.user);

  // On app startup - we fire off an action so that we wait to load the app
  const setJwtValidationFinished = useCallback(() => {
    if (!jwtValidationFinished) {
      dispatch(setUi({ jwtValidationFinished: true }));
    }
  }, [dispatch, jwtValidationFinished]);

  // Track redux user in a ref so that the token validator interval isn't continously
  // being reset.
  const reduxUserRef = useUpdatingRef(user);

  useEffect(() => {
    const validateToken = async () => {
      const { jwt } = getCurrentAuthCookies();
      if (!jwt) {
        if (reduxUserRef.current.user_id !== null) {
          consoleDev('No jwt read, ensuring log out');
          // NOTE: This is necessary because if a user logged out in say SoundSystem and was coming back to this app,
          // we may not have cleared user related data from the persisted store yet.
          dispatch(logoutUser());
        }

        return;
      }

      const parsedJwt = jwtDecode<ProtonJwt>(jwt);
      if (reduxUserRef.current.user_id !== parsedJwt.user_id) {
        consoleDev('User id changed, logging out and switching user');
        const { refreshToken } = getCurrentAuthCookies();
        const user = await fetchUser(parsedJwt.user_id, jwt);
        dispatch(logoutUser({ persistAuthCookies: true }));
        dispatch(
          setToken({
            userId: parsedJwt.user_id,
            jwt,
            refreshToken
          })
        );
        dispatch(replaceUser(user));
        return;
      }

      try {
        const currentTimeSeconds = Date.now() / 1000;
        const remainingSeconds = parsedJwt.exp - currentTimeSeconds;
        if (remainingSeconds <= VALIDATE_THRESHOLD_SECONDS) {
          consoleDev('Expired / expiring jwt. Refreshing');
          const tokenPayload = await dispatch(refresh());
          if (tokenPayload && tokenPayload.userId) {
            await dispatch(getUser(tokenPayload.userId)).catch(console.error);
            return;
          }
        }
      } catch (e: unknown) {
        e instanceof Error &&
          logError(new Error('Encountered error during token refresh', e));
        dispatch(logoutUser());
      }
    };

    validateToken().catch(logError).finally(setJwtValidationFinished);

    const intervalId = setInterval(() => void validateToken(), VALIDATE_PERIOD_MS);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, setJwtValidationFinished]);
}

export default useTokenValidator;
