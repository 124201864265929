import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FilePickerActionMenu } from 'components/ActionMenu';
import { EllipsisButton } from 'components/Button';
import MenuToggler from 'components/MenuToggler';
import Select from 'components/Form/Inputs/Select/Select';

import useMutationWithAlert from 'hooks/useMutationWithAlert';

import {
  COLORS,
  AUDIO_FILE_TYPES,
  MEDIUM_BREAK_POINT,
  BREAKPOINTS
} from 'config/constants';
import routeMap from 'config/routes';
import { LOSSLESS_DOWNLOADS } from 'config/features/featureFlags';

import {
  getDownloadLocationOptions,
  getCurrentDownloadLocationOption
} from 'helpers/downloadLocation';

import { canDownloadLossless, trackDownloadConfig } from 'helpers';
import useBreakpoint from 'hooks/useBreakpoint';
import useCurrentUser from 'hooks/useCurrentUser';
import { selectIsFeatureActive } from 'redux/selectors';

const UpdateUserSettingsMutation = `
  mutation updateUserSettings ($input: UserSettingUpdateInput!) {
    userSettingUpdate (input: $input) {
      errors
      userSetting {
        enabled
        settingType
        value
      }
    }
  }
`;

const SelectWrapper = styled.div`
  padding: 2rem 2rem 0.01rem 2rem;

  > div {
    margin-bottom: 2rem;
  }
`;

const MoreWrapper = styled.div`
  background: #fff;
  border-radius: 0.6rem;
  cursor: pointer;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    opacity: 1;
  }
`;

const FILE_TYPE_OPTIONS = Object.values(AUDIO_FILE_TYPES).map(item => ({
  label: item.LABEL,
  value: item.VALUE
}));

const PromoReactionDropdownMenu = ({ disabled, isVisible: _isVisible, track }) => {
  const history = useHistory();

  const { user } = useSelector(state => ({
    user: state.user,
    isLosslessDownloadsActive: selectIsFeatureActive(state, LOSSLESS_DOWNLOADS)
  }));
  const { user: currentUser } = useCurrentUser();
  const isMediumScreen = useBreakpoint(BREAKPOINTS.MD, useBreakpoint.DIRECTIONS.DOWN);

  const [, updateUserSettings] = useMutationWithAlert(UpdateUserSettingsMutation);

  const losslessDownloadsAvailable = canDownloadLossless(currentUser, { track, user });
  const downloadConfig = trackDownloadConfig(currentUser, { track, user });

  const currentFileTypeOption = FILE_TYPE_OPTIONS.find(
    ({ value }) => value === downloadConfig.filetype
  );

  const downloadLocationOptions = getDownloadLocationOptions(user);
  const currentLocationOption = getCurrentDownloadLocationOption(currentUser, user);

  const handleSettingsUpdate = (settingType, value) => {
    if (!value) {
      history.push(routeMap.account.root + routeMap.account.connections);
      return;
    }

    updateUserSettings({
      input: {
        userId: String(user.user_id),
        settingType,
        value,
        enabled: true,
        __typename: undefined
      }
    });
  };

  const handleLocationUpdate = value => handleSettingsUpdate('DOWNLOAD_LOCATION', value);

  return (
    <MenuToggler
      color={COLORS.GRAY_MEDIUM}
      size={14}
      forceAcionMenuDisplay={isMediumScreen}
      renderToggle={({ isOpen, isVisible, toggle }) => {
        const isActive = isOpen && isVisible;

        return (
          <MoreWrapper $isVisible={_isVisible || isActive}>
            <EllipsisButton
              isActive={isActive}
              onClick={toggle}
              disabled={disabled}
              data-testid="PromoReactionDropdownMenu--Picker"
            />
          </MoreWrapper>
        );
      }}
      renderPopoverContent={({ close }) => (
        <SelectWrapper data-testid="track-settings-popover">
          <Select
            value={currentFileTypeOption}
            label="File Type"
            options={FILE_TYPE_OPTIONS}
            disabled={!losslessDownloadsAvailable}
            onChange={e => {
              handleSettingsUpdate('DOWNLOAD_FORMAT', e.value);
              setTimeout(() => close(), 300);
            }}
            formik={false}
          />
          <Select
            value={currentLocationOption}
            label="Download Location"
            options={downloadLocationOptions}
            onChange={e => {
              handleLocationUpdate(e.value);
              setTimeout(() => close(), 300);
            }}
            formik={false}
            style={{ minWidth: '28rem' }}
          />
        </SelectWrapper>
      )}
      renderActionMenu={({ isOpen, close }) => (
        <FilePickerActionMenu
          isOpen={isOpen}
          close={close}
          closeOnNavigation
          handleSettingsUpdate={handleSettingsUpdate}
          downloadLocationOptions={downloadLocationOptions}
          currentLocationOption={currentLocationOption}
          track={track}
        />
      )}
    />
  );
};

PromoReactionDropdownMenu.SELECTORS = {
  PICKER: '[data-testid=PromoReactionDropdownMenu--Picker]'
};

PromoReactionDropdownMenu.propTypes = {
  disabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  track: PropTypes.shape()
};

export default PromoReactionDropdownMenu;
