import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { displayAlertError } from 'helpers';

import StarRating from 'components/StarRating';
import {
  selectPromoTrackReactionArtistId,
  selectPromoTrackReaction
} from 'redux/selectors/promoReleases';
import { updateTrackReaction as updateTrackReactionAction } from 'redux/actions/promoReleases';
import { downloadTrack as downloadTrackAction } from 'redux/actions/tracks';
import useCurrentUser from '../hooks/useCurrentUser';
import { trackDownloadConfig } from '../helpers/tracks';

/**
 * [PromoTrackStarRating] - handles display of rating from provided track as
 * well as provides necessary change handlers
 *
 * @param {object} track
 * @param {object} reaction
 * @param {number[]} artistIds
 * @param rest - can include any other StarRating props such as size or disabled
 */

const PromoTrackStarRating = ({ track, format, ...rest }) => {
  const { user: currentUser } = useCurrentUser();
  const dispatch = useDispatch();

  const { user, reaction, artistId } = useSelector(state => ({
    user: state.user,
    reaction: selectPromoTrackReaction(state, track.id) || {},
    artistId: selectPromoTrackReactionArtistId(state, track.release.id, track.id)
  }));

  return (
    <StarRating
      rating={reaction.rating}
      onChange={rating => {
        const updateArgs = {
          track,
          artistId,
          rating
        };

        const tryAutoDownload = () => {
          const autoDownloadSetting = currentUser.settings['AUTO_DOWNLOAD'];
          if (autoDownloadSetting.enabled && rating >= autoDownloadSetting.value) {
            return dispatch(
              downloadTrackAction.call(
                trackDownloadConfig(currentUser, { user, track, format })
              )
            );
          }
        };

        dispatch(
          updateTrackReactionAction.call(updateArgs, {
            onSuccess: tryAutoDownload,
            onError: displayAlertError,
            optimistic: true
          })
        );
      }}
      {...rest}
    />
  );
};

PromoTrackStarRating.SIZE = StarRating.SIZE;

PromoTrackStarRating.propTypes = {
  // for connect
  track: PropTypes.shape({
    id: PropTypes.number.isRequired,
    release: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,

  updateTrackReaction: PropTypes.func
};

export default PromoTrackStarRating;
