import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { selectIsFeatureActive } from 'redux/selectors';
import { TRACK_PAGE } from 'config/features/featureFlags';

import Popover from 'components/Popover';
import { EllipsisButton } from 'components/Button';
import Flex from 'components/Flex';
import TextHeader from 'components/TextHeader';
import TrackTitleWithRemixers from 'components/TrackTitleWithRemixers';
import PlayerTitle from './PlayerTitle';

const StyledWrapper = styled(Flex)`
  padding: 0 1rem;
`;

const Container = styled.div`
  color: #7d7d7d;
  display: flex;
  height: 16rem;
`;

const TrackArt = styled.div`
  border-radius: 4px 0 0 4px;
  flex: 0 0 16rem;
  overflow: hidden;
  width: 16rem;

  img {
    width: 100%;
  }
`;

const TrackDetails = styled(Flex)`
  padding: 1.5rem;
  width: 28rem;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

const PlayerDetailsPopover = ({ playing, isTrackPageActive }) => (
  <StyledWrapper align="center" justify="flex-end" grow={0}>
    <Popover
      renderToggle={({ open }) => (
        <EllipsisButton
          style={{ alignItems: 'center', display: 'flex' }}
          data-testid="Player-popover-toggle"
          onClick={open}
          dark
        />
      )}
      renderDirection={Popover.DIRECTIONS.UP}
      fixed
      dataTestId="Player-details-popover"
    >
      <Container>
        <Link to={playing.release.slug} data-testid="Popover-cover-art">
          <TrackArt>
            <img
              src={playing.release.cover_art.small.url}
              alt={`${playing.title} release cover art`}
            />
          </TrackArt>
        </Link>

        <TrackDetails column>
          <Flex shrink={0} column>
            <TextHeader
              type={TextHeader.TYPES.LARGE}
              weight={TextHeader.WEIGHTS.HEAVY}
              data-testid="Popover-artists"
              className="mb-0"
            >
              <PlayerTitle playing={playing} />
            </TextHeader>

            <TrackTitleWithRemixers playing={playing} data-testid="Popover-title" />
          </Flex>

          <Flex className="mt-3" align="flex-end" grow={1}>
            <div>
              <TextHeader
                type={TextHeader.TYPES.XSMALL}
                weight={TextHeader.WEIGHTS.HEAVY}
                style={{ color: '#666', marginBottom: '0.3rem' }}
                uppercase
              >
                Music Label
              </TextHeader>
              <Link to={playing.label.slug} data-testid="Popover-label">
                {playing.label.name}
              </Link>

              {isTrackPageActive && (
                <TextHeader
                  type={TextHeader.TYPES.SMALL}
                  weight={TextHeader.WEIGHTS.HEAVY}
                  uppercase
                  className="mb-0 mt-2"
                >
                  <Link to={playing.slug}>Go To Track Page</Link>
                </TextHeader>
              )}
            </div>
          </Flex>
        </TrackDetails>
      </Container>
    </Popover>
  </StyledWrapper>
);

PlayerDetailsPopover.propTypes = {
  playing: PropTypes.shape(),
  // connect:
  isTrackPageActive: PropTypes.bool
};

export default connect(
  state => ({
    isTrackPageActive: selectIsFeatureActive(state, TRACK_PAGE)
  }),
  {}
)(PlayerDetailsPopover);
