import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from 'redux/selectors/user';
import {
  selectPromoTrackReaction,
  selectPromoTrackArtistIds
} from 'redux/selectors/promoReleases';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import { useQuery } from 'urql';
import { ReleaseDspQuery } from 'graphql/queries/release';
import { releaseStoreInfoButtonsTransform } from 'graphql/transforms';

import Button from 'components/Button';
import DownloadTrackButton from 'components/Button/DownloadTrackButton';
import { BeatportIcon } from 'components/Icons';
import PromoArtistPickerButton from 'components/PromoArtistPickerButton';
import Flex from 'components/Flex';
import PromoFeedbackForm from 'components/PromoFeedbackForm';
import PromoTrackStarRating from 'components/PromoTrackStarRating';
import StarRating from 'components/StarRating';
import useCurrentUser from 'hooks/useCurrentUser';

import {
  Wrapper,
  Row,
  RatingContainer,
  RatingContainerInner,
  ActionsButtonGroup
} from './styled';
import Tooltip from 'components/Tooltip';
import styled from 'styled-components';
import { trackDownloadConfig } from 'helpers/tracks';

const StyledTooltip = styled(Tooltip)`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;

/**
 * [PromoReactionWidget] - content to render within the track reaction popover and track action menu.
 * Allows user to rate tracks, change artist rating the track, provide feedback, and download
 *
 * @param {object} track - track popover content will be rendered for
 * @param {string} close - method to close popover that content is rendered within
 * @param {func} onHeightChange - callback to fire when promo feedback form size changes
 * @param {boolean} showStoreLinks - enables rendering Beatport/Spotify links in widget
 */

// eslint-disable-next-line react/display-name
const PromoReactionWidget = React.memo(
  ({
    format,
    track,
    onHeightChange,
    showStoreLinks = false,
    onDownload,
    onDownloadSuccess,

    // popover props
    close
  }) => {
    const { user: currentUser } = useCurrentUser();
    const [{ data, fetching, error }] = useQuery({
      query: ReleaseDspQuery,
      variables: { id: track.release.id },
      pause: !track.release.id
    });
    const dspLinks = fetching || error ? null : releaseStoreInfoButtonsTransform(data);
    const beatport = dspLinks?.find(dsp => dsp.name === 'beatport');

    const { user, reaction, promoArtistIds } = useSelector(state => ({
      user: selectUser(state),
      reaction: selectPromoTrackReaction(state, track.id),
      promoArtistIds: selectPromoTrackArtistIds(state, {
        trackId: track.id,
        releaseId: track.release.id
      })
    }));

    const downloadConfig = trackDownloadConfig(currentUser, { track, user, format });
    const downloadFormat = downloadConfig.filetype;

    const multipleArtists = promoArtistIds.length > 1;
    const hasRating = reaction && reaction.rating;
    const hasOptimisticRating = reaction?.__optimistic?.rating;

    return (
      <Wrapper data-testid={PromoReactionWidget.TEST_IDS.CONTAINER}>
        {showStoreLinks && !!beatport && !fetching && (
          <Row>
            <ActionsButtonGroup data-testid={PromoReactionWidget.TEST_IDS.STORE_LINKS}>
              {beatport && (
                <Button
                  to={beatport.url}
                  newTab
                  testId={PromoReactionWidget.TEST_IDS.STREAM_SPOTIFY}
                  color={Button.COLORS.TRANSPARENT}
                  expand
                  prepend={() => <BeatportIcon />}
                  text={beatport.title}
                />
              )}
            </ActionsButtonGroup>
          </Row>
        )}

        <Row>
          <RatingContainer data-testid={PromoReactionWidget.TEST_IDS.STAR_RATING}>
            <RatingContainerInner>
              <PromoTrackStarRating
                format={downloadFormat}
                size={StarRating.SIZE.LARGE}
                track={track}
              />
            </RatingContainerInner>
          </RatingContainer>

          <StyledTooltip
            enabled={!hasRating}
            content="Rating required before you can download."
            triggerContainerElement={'div'}
          >
            <ActionsButtonGroup>
              <Flex style={{ paddingLeft: 24 }}>
                <DownloadTrackButton
                  track={track}
                  format={downloadFormat}
                  onDownload={onDownload}
                  onDownloadSuccess={() => {
                    onDownloadSuccess && onDownloadSuccess();
                    close && close();
                  }}
                  disabled={
                    !hasRating ||
                    ((!hasRating || !hasOptimisticRating) && reaction.__updating)
                  }
                />
              </Flex>
            </ActionsButtonGroup>
          </StyledTooltip>
        </Row>

        <Row>
          <Flex.Item grow={0} shrink={0} basis="42px">
            <PromoArtistPickerButton
              track={track}
              compact
              renderDirection={PromoArtistPickerButton.DIRECTIONS.DOWN}
              multipleArtists={multipleArtists}
            />
          </Flex.Item>

          <Flex.Item grow={1}>
            <PromoFeedbackForm
              autoFocus={false}
              onHeightChange={onHeightChange}
              track={track}
            />
          </Flex.Item>
        </Row>
      </Wrapper>
    );
  }
);

PromoReactionWidget.TEST_IDS = {
  CONTAINER: 'PromoReactionWidget',
  STORE_LINKS: 'PromoReactionWidget-store-links',
  STAR_RATING: 'PromoReactionWidget-StarRating',
  BUY_BEATPORT: 'ActionMenuItem-buy-on-beatport',
  STREAM_SPOTIFY: 'ActionMenuItem-stream-on-spotify'
};

PromoReactionWidget.SELECTORS = generateSelectorsFromTestIds(
  PromoReactionWidget.TEST_IDS
);

PromoReactionWidget.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    version: PropTypes.string
  }),
  close: PropTypes.func,
  onHeightChange: PropTypes.func,
  showStoreLinks: PropTypes.bool
};

export default PromoReactionWidget;
