import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { lighten } from 'polished';
import styled from 'styled-components';
import _ from 'lodash';

import { COLORS } from 'config/constants/styles';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import Icon from './Icon';
import Flex from './Flex';

const RatingContainer = styled.div`
  cursor: ${({ disabled, $displayOnly }) => !disabled && !$displayOnly && 'pointer'};
  margin: 0 auto;
  max-width: 30rem;
  position: relative;
  width: 100%;

  path {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

/**
 * [StarRating]
 * Equally spaces stars inside parent elements.
 *
 * @param {number} rating - rating number to highlight stars up to.
 * @param {boolean} disabled - show disabled state for picker (won't be able to select)
 * @param {boolean} [displayOnly] - show current value but don't allow user interaction
 * @param {string} [grayScale] - star ratings will show without orange color
 * @param {number} [size=SMALL] px size (height/width) of stars
 * @param {function} [onChange] - Promise that receives the rating as the 1st argument.
 * If onChange is not passed, the star component will be display only.
 * @param {number} [maxRating=5] set the number of stars that will be displayed
 */

const StarRating = ({
  className,
  rating,
  defaultColor = COLORS.GRAY_LIGHT,
  disabled,
  displayOnly: _displayOnly,
  grayScale,
  onChange,
  size = StarRating.SIZE.SMALL,
  maxRating = 5,
  style
}) => {
  const STAR_COLOR_HOVER = lighten(0.07, COLORS.PRIMARY);
  const STAR_COLOR_RATED = COLORS.PRIMARY;
  const STAR_COLOR_UNRATED = defaultColor;

  const [hoverRating, setHoverRating] = useState(null);

  const displayOnly = _displayOnly || !onChange;
  const ratingValue = rating || 0;

  const getFillColor = starRating => {
    // If hover rating set, return hover color for hover rated values (unless disabled)
    if (hoverRating && !disabled && starRating <= hoverRating) return STAR_COLOR_HOVER;

    // Else, if star is within rated range, show rated color
    if (starRating <= ratingValue) {
      if (grayScale) return COLORS.GRAY_MEDIUM;
      return STAR_COLOR_RATED;
    }

    // otherwise, star is not within rated range and should use unrated color
    return STAR_COLOR_UNRATED;
  };

  return (
    <RatingContainer
      data-testid={StarRating.TEST_IDS.CONTAINER}
      disabled={disabled}
      $displayOnly={displayOnly}
      className={className}
      style={style}
    >
      <Flex
        justify="space-between"
        data-testid={StarRating.TEST_IDS.getTestIdForSelectedValue(
          _.isNumber(rating) ? rating : 'none'
        )}
      >
        {[...Array(maxRating)].map((__, index) => {
          const starRatingValue = index + 1;

          return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <Icon
              data-testid={StarRating.TEST_IDS.getTestIdForStarValue(starRatingValue)}
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              type={Icon.TYPES.STAR}
              color={getFillColor(starRatingValue)}
              onClick={() => {
                if (disabled || displayOnly) return;
                if (onChange) onChange(starRatingValue);
              }}
              onMouseOver={() => {
                if (!displayOnly) setHoverRating(starRatingValue);
              }}
              onMouseOut={() => {
                if (!displayOnly) setHoverRating(null);
              }}
              width={size}
              height={size}
            />
          );
        })}
      </Flex>
    </RatingContainer>
  );
};

const getTestIdForStarValue = value => `StarRating-star-${value}`;
const getTestIdForSelectedValue = value => `StarRating-rated-value-${value}`;

StarRating.TEST_IDS = {
  CONTAINER: 'StarRating-container',
  // NOTE: The STAR_X test ids are often used for click on a star in tests
  getTestIdForStarValue,
  STAR_1: getTestIdForStarValue(1),
  STAR_2: getTestIdForStarValue(2),
  STAR_3: getTestIdForStarValue(3),
  STAR_4: getTestIdForStarValue(4),
  STAR_5: getTestIdForStarValue(5),
  // NO_RATING id will be assigned if there isn't a rating applied to the component
  NO_RATING: getTestIdForSelectedValue('none'),
  getTestIdForSelectedValue
};

StarRating.SELECTORS = generateSelectorsFromTestIds(StarRating.TEST_IDS);

StarRating.SIZE = {
  XSMALL: 10,
  SMALL: 14,
  LARGE: 16
};

StarRating.propTypes = {
  defaultColor: PropTypes.string,
  displayOnly: PropTypes.bool,
  rating: PropTypes.number,
  size: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
  maxRating: PropTypes.number,
  disabled: PropTypes.bool,
  style: PropTypes.shape()
};

export default StarRating;
