import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import ActionMenu from 'components/ActionMenu/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import ActionMenuItem from 'components/ActionMenu/ActionMenuItem';
import { COLORS } from 'config/constants';
import Icon from 'components/Icon';
import PromoArtistPickerButton from 'components/PromoArtistPickerButton';

const ArtistImage = styled.img`
  border: ${({ active }) => (active ? `solid 1px ${COLORS.PRIMARY}` : 'none')};
  border-radius: 99999rem;
  height: 3.4rem;
  margin-right: 0.5rem;
  padding: 0.2rem;
  width: 3.4rem;
`;

// TODO: Replace w/ Flex component when available
const ActionItemContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

/**
 * [ArtistPickerActionMenu] - Menu that shows list of artists as action items.
 * Supports highlighting currently selected artist.
 *
 * Ex: used for choosing artists reacting to a track if user manages multiple artist that have
 * promo access to that track
 *
 * @param {object[]} artists - artist to be displayed
 * @param {number} [selectedArtistId] - id of artist currently selected
 * @param {function} onArtistSelect - what to do when artist is selected (controlled component)
 *
 * Action menu props (probably from useToggle)
 * @param {bool} isOpen
 * @param {function} close
 */

const ArtistPickerActionMenu = ({
  artists,
  onArtistSelect,
  selectedArtistId,
  disabled,
  isOpen,
  close,
  closeOnNavigation
}) => (
  <ActionMenu isOpen={isOpen} close={close} closeOnNavigation={closeOnNavigation}>
    <ActionMenuList closeMenu={close}>
      {artists.map(artist => {
        const { id, imageUrl, name } = artist;
        const active = id === selectedArtistId;

        return (
          <ActionMenuItem
            key={id}
            onClick={() => onArtistSelect(artist)}
            disabled={disabled}
            data-testid={PromoArtistPickerButton.TEST_IDS.getTestIdForArtistOption(id)}
          >
            <ArtistImage src={imageUrl} alt="artist avatar" active={active} />{' '}
            <ActionItemContent>
              <div>{name}</div>
              {active && (
                <Icon
                  type={Icon.TYPES.CHECK_MARK}
                  color={Icon.COLORS.PRIMARY}
                  width={15}
                />
              )}
            </ActionItemContent>
          </ActionMenuItem>
        );
      })}
    </ActionMenuList>
  </ActionMenu>
);

ArtistPickerActionMenu.propTypes = {
  onArtistSelect: PropTypes.func,
  selectedArtistId: PropTypes.number,
  artists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      imageUrl: PropTypes.string
    })
  ),
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  closeOnNavigation: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ArtistPickerActionMenu;
