import { DspConnection, DspName } from 'types';
import { ReleaseStatusEnum } from 'gql/graphql';
import { DIGITAL_SERVICE_PROVIDERS } from 'types/constants';

/**
 * Returns the username from the connected service.
 * For some reason, this is stored differently for SoundCloud.
 */

export const getDspConnectionName = (connection: DspConnection) =>
  connection.dsp === 'soundcloud' ? connection.username : connection.value;

/**
 * Returns true if a dsp connection for an artist can be verified.
 * Currently only spotify and soundcloud connections can be verified.
 */

export const isDspConnectionVerifiable = (connection?: DspConnection) =>
  !!connection &&
  (connection.dsp === 'spotify' || connection.dsp === 'soundcloud') &&
  !connection.verified;

/**
 * Returns a title value for DSP buttons based on release status
 */

export const dspButtonTitle = (
  status: Exclude<ReleaseStatusEnum, 'NOT_AVAILABLE'>,
  name: DspName
) => {
  const formattedName = DIGITAL_SERVICE_PROVIDERS[name];

  switch (status) {
    case ReleaseStatusEnum.Released:
      return `${name === 'beatport' ? 'Download' : 'Stream'} on ${formattedName}`;

    case ReleaseStatusEnum.Preorder:
      return `Pre-order on ${formattedName}`;

    case ReleaseStatusEnum.Unreleased:
      return `Coming Soon on ${formattedName}`;
  }
};
