import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _capitalize from 'lodash/capitalize';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';

import { toggleSearchResultsDisplay as toggleSearchResultsDisplayAction } from 'redux/actions/search';
import { isSearchExhaustive } from 'redux/actions/search/helpers';
import { SEARCH_TYPES } from 'config/constants';

import Badge from 'components/Badge';
import TextHeader from 'components/TextHeader';
import UniversalSearchResultRow from 'components/UniversalSearchResultRow';
import searchStartIcon from 'images/icon-search-start.svg';
import { getOrderedResultCategories } from './helpers';

const areResultsHidden = (results, maxResultsPerCategory, categories) => {
  if (!maxResultsPerCategory) return false;
  let resultsHidden = false;

  categories.forEach(type => {
    const resultTypeCount = results[type].length;
    if (maxResultsPerCategory && resultTypeCount > maxResultsPerCategory) {
      resultsHidden = true;
    }
  });
  return resultsHidden;
};

export const UniversalSearchResults = ({
  className = '',
  searchQuery,
  resultCount,
  results,
  maxResultsPerCategory,
  toggleSearchResultsDisplay,
  isFetching,
  user,
  ...rest
}) => {
  let state = null;

  if (searchQuery && resultCount === 0 && !isFetching) {
    state = 'NO_RESULTS';
  } else if (resultCount > 0) {
    state = 'SHOW_RESULTS';
  }

  const resultCategories = getOrderedResultCategories(user);

  switch (state) {
    case 'SHOW_RESULTS':
      return (
        <div className={`UniversalSearchResults__container ${className}`}>
          <div className="UniversalSearchResults__results">
            {resultCategories.map(type => {
              const resultTypeCount = results[type].length;
              if (resultTypeCount === 0) return null;

              const classes = `${type} section section--small`;
              const typeTitle = SEARCH_TYPES[type] || _capitalize(type);

              const isExhaustive = isSearchExhaustive(type, resultTypeCount);
              const badgeText = isExhaustive ? `${resultTypeCount}+` : resultTypeCount;

              return (
                <div key={type}>
                  <Element name={type} className={classes}>
                    <div className="UniversalSearchResults__results__label">
                      <TextHeader type={TextHeader.TYPES.LARGE} className="mb-0 mr-2">
                        {typeTitle}
                      </TextHeader>
                      <Badge type={Badge.TYPES.SECONDARY} noMargin>
                        {badgeText}
                      </Badge>
                    </div>

                    <div className={`UniversalSearchResults__results__content ${type}`}>
                      {results[type].map((result, i) => {
                        if (maxResultsPerCategory && i >= maxResultsPerCategory)
                          return null;

                        return (
                          <UniversalSearchResultRow
                            key={result.key}
                            className={`js-${type}-result-row`}
                            searchQuery={searchQuery}
                            result={result}
                            hideSearchResults={() => toggleSearchResultsDisplay(false)}
                            {...rest}
                          />
                        );
                      })}
                    </div>

                    {resultTypeCount > maxResultsPerCategory && (
                      <Link
                        to={{
                          pathname: '/search',
                          hash: `#${type}`
                        }}
                        onClick={() => toggleSearchResultsDisplay(false, true)}
                      >
                        <div className="UniversalSearchResults__show-more js-show-more">
                          More {_capitalize(type)}
                        </div>
                      </Link>
                    )}
                  </Element>
                </div>
              );
            })}
          </div>
          {areResultsHidden(results, maxResultsPerCategory, resultCategories) && (
            <Link to="/search" onClick={() => toggleSearchResultsDisplay(false, true)}>
              <div className="UniversalSearchResults__see-all">See All Results</div>
            </Link>
          )}
        </div>
      );

    case 'NO_RESULTS':
      return (
        <div className={`UniversalSearchResults__container ${className}`}>
          <div className="UniversalSearchResults__get-started">
            <div>We couldn&apos;t find any results</div>
          </div>
        </div>
      );

    default:
      return (
        <div className={`UniversalSearchResults__container ${className}`}>
          <div className="UniversalSearchResults__get-started">
            <div className="UniversalSearchResults__get-started__inner">
              <div className="UniversalSearchResults__get-started__avatar">
                <img src={searchStartIcon} alt="" />
              </div>
              <div>{isFetching ? 'Searching...' : 'What are you looking for?'}</div>
            </div>
          </div>
        </div>
      );
  }
};

UniversalSearchResults.propTypes = {
  // redux connect props
  user: PropTypes.shape(),
  results: PropTypes.shape({
    artists: PropTypes.array.isRequired,
    shows: PropTypes.array.isRequired,
    genres: PropTypes.array.isRequired,
    tracklists: PropTypes.array.isRequired
  }),
  searchQuery: PropTypes.string,
  resultCount: PropTypes.number,
  isFetching: PropTypes.bool,

  // redux connect actions
  toggleSearchResultsDisplay: PropTypes.func.isRequired,

  // passed from parent
  maxResultsPerCategory: PropTypes.number
};

export default connect(
  state => ({
    user: state.user,
    searchQuery: state.search.query,
    results: state.search.results,
    resultCount: state.search.count,
    isFetching: state.search.isFetching
  }),
  {
    toggleSearchResultsDisplay: toggleSearchResultsDisplayAction
  }
)(UniversalSearchResults);
