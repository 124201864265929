import styled from 'styled-components';

import { BREAKPOINTS } from 'config/constants';

import { ContainerPaddingCss, InverseContainerCss } from 'components/Layout/Container';

export const Actions = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const StyledNavTabWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 1rem;
  ${InverseContainerCss}
  ${ContainerPaddingCss}
  padding-bottom: 1.5rem;
  overflow-x: auto;

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    justify-content: center;
    margin-top: 0;
  }
`;

export const StyledTabbedHeader = styled.header<{ $collapse?: boolean }>`
  background: #eee;
  color: #fff;
  position: relative;
  min-height: 145px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: end;

  @media screen and (max-width: ${BREAKPOINTS.LG}px) {
    .TabbedHeader__header__tabs {
      margin-top: 2rem;
      position: relative;
      text-align: center;
    }

    .TabbedHeaderTombstone__header__body {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Content = styled.div`
  padding: 3rem 0 0 0;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${BREAKPOINTS.LG}px) {
    padding-top: 2rem;
  }
`;

export const StyledContentBody = styled.div<{ $collapse?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: ${BREAKPOINTS.MD}px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: auto;
    padding-bottom: 0;
    text-align: center;
    width: 100%;
  }
`;

export const CollageContainer = styled.div`
  display: flex;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  &:after {
    background: rgba(0, 0, 0, 0.8);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;

export const CollageImage = styled.img`
  height: 100%;
  filter: grayscale(100%);
  flex: 0 0 auto;
`;
