import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

class TextHeader extends Component {
  static TYPES = {
    XSMALL: {
      class: 'xsmall',
      tag: 'h6'
    },
    SMALL: {
      class: 'small',
      tag: 'h5'
    },
    MEDIUM: {
      class: 'medium',
      tag: 'h4'
    },
    LARGE: {
      class: 'large',
      tag: 'h3'
    },
    XLARGE: {
      class: 'xlarge',
      tag: 'h2'
    },
    JUMBO: {
      class: 'jumbo',
      tag: 'h1'
    }
  };

  static WEIGHTS = {
    LIGHT: 'TextHeader--weight--light',
    NORMAL: 'TextHeader--weight--normal',
    HEAVY: 'TextHeader--weight--heavy'
  };

  static COLORS = {
    LIGHT: 'TextHeader--color--light',
    SECONDARY: 'TextHeader--color--secondary',
    PRIMARY: 'TextHeader--color--primary',
    WHITE: 'TextHeader--color--white'
  };

  render() {
    const {
      type,
      children,
      customTag,
      className,
      style,
      color,
      weight = TextHeader.WEIGHTS.HEAVY,
      title,
      truncate,
      uppercase,
      colorLinks,
      underline,
      ...rest
    } = this.props;

    const HeaderTag = customTag || `${type?.tag}`;

    const _classes = classnames(
      'TextHeader',
      `TextHeader--${type?.class}`,
      color,
      weight,
      className,
      {
        'TextHeader--ellipsis-overflow': truncate,
        'TextHeader--uppercase': uppercase,
        'TextHeader--colorLinks': colorLinks,
        'TextHeader--underline': underline
      }
    );

    // Pass separate title prop if you want to leverage highlighting of clipped text if children is not a string
    const headerTitle =
      truncate && children && typeof children === 'string' ? children : title;

    return (
      <HeaderTag
        // show title on hover if using truncate prop
        title={headerTitle}
        className={_classes}
        style={style}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </HeaderTag>
    );
  }
}

TextHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  // eslint-disable-next-line no-unused-vars
  type: PropTypes.oneOf(Object.entries(TextHeader.TYPES).map(([key, value]) => value)),
  title: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  colorLinks: PropTypes.bool,
  customTag: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  truncate: PropTypes.bool,
  uppercase: PropTypes.bool,
  underline: PropTypes.bool
};

export default TextHeader;
