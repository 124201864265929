import Button from 'components/Button';
import TextEmphasis from 'components/TextEmphasis';
import {
  InviteSubscriberToLabel,
  RemoveSubscriberFromLabel
} from 'graphql/queries/label';
import useMutationWithAlert from 'hooks/useMutationWithAlert';
import React from 'react';
import Alert, { AlertType } from '../Alert';

interface Props {
  dismissAlert: () => void;
  type: AlertType;
  action: 'added' | 'removed';
  artist: {
    id: number;
    name: string;
  };
  label: {
    id: number;
    name: string;
  };
}

/**
 * [LabelSubscriberAction] - Custom alert for when a label subscriber is added or removed which provides an undo action.
 *
 * TODO: use in the Add subscribers / Recommended subscribers page (still v2 api)
 */

export const LabelSubscriberAction = ({
  dismissAlert,
  type,
  action,
  artist,
  label
}: Props) => {
  const undoMutationQuery =
    action === 'added' ? RemoveSubscriberFromLabel : InviteSubscriberToLabel;

  const [{ fetching }, undoSubscriberAction] = useMutationWithAlert(undoMutationQuery);

  const handleUndo = async () => {
    const { hasErrors } = await undoSubscriberAction({
      artistId: String(artist.id),
      labelId: String(label.id)
    });
    if (!hasErrors) dismissAlert();
  };

  const actionText = action === 'added' ? 'invited to' : 'removed from';

  return (
    <>
      <Alert.Content data-testid="LabelSubscriberAction">
        <TextEmphasis>{artist.name}</TextEmphasis> was {actionText}{' '}
        <strong>{label.name}</strong>&apos;s promo pool.
      </Alert.Content>

      <Alert.ButtonGroup>
        <Button
          data-testid="LabelSubscriberAlert-undo"
          disabled={fetching}
          onClick={handleUndo}
          color={Button.COLORS.LINK}
        >
          Undo
        </Button>

        <Alert.DismissButton onClick={dismissAlert} disabled={fetching} type={type} />
      </Alert.ButtonGroup>
    </>
  );
};
