import _ from 'lodash';
import { matchPath } from 'react-router-dom';

import {
  ARTIST_ROLES,
  AUDIO_TYPES,
  AUDIO_FILE_TYPES,
  DownloadFileType,
  ArtistRole,
  AudioType
} from 'config/constants';
import routeMap from 'config/routes';
import history from 'config/history';
import { isAdminUser } from 'helpers/user';
import { AlgoliaTrack, AlgoliaArtist, V2ProUser } from 'types';
import { User } from 'hooks/useCurrentUser';
import { getUserProfilesById } from './user';

const LINK_ICON = 'icon-link.svg';

type Audio = AlgoliaTrack & { hasArchiveAccess: boolean; __audioType: AudioType };

// common actions for viewing track details
export const getTrackActions = ({
  track,
  isTrackPageActive,
  dspLinks
}: {
  track: AlgoliaTrack;
  isTrackPageActive: boolean;
  dspLinks: { title: string; url: string; disabled: boolean }[] | null;
}) => {
  const { pathname } = history.location;
  const onTrackPage = matchPath(pathname, {
    path: routeMap.tracks.root
  });
  const onReleasePage = matchPath(pathname, {
    path: routeMap.releases.root
  });

  const artistActions = track.artists.map(a => ({
    name: `View ${a.name}`,
    icon: LINK_ICON,
    to: `${a.slug}/tracks`
  }));

  const dspActions = dspLinks?.map(dsp => ({
    name: dsp.title,
    to: dsp.url,
    disabled: dsp.disabled,
    newTab: true
  }));

  return [
    ...(isTrackPageActive && !onTrackPage
      ? [
          {
            name: 'View Track',
            icon: LINK_ICON,
            to: track.slug
          }
        ]
      : []),
    ...artistActions,
    {
      name: `View Label`,
      icon: LINK_ICON,
      to: `${track.label.slug}/tracks`
    },
    ...(!onReleasePage
      ? [
          {
            name: `View Release`,
            icon: LINK_ICON,
            to: track.release.slug
          }
        ]
      : []),
    {
      divider: Number(dspActions?.length) > 0
    },
    ...(dspActions || [])
  ];
};

// trackOrArtists can be track object or artists array
export const getTrackArtistsByRole = (
  trackOrArtists: AlgoliaTrack | AlgoliaTrack['artists'],
  roleName: ArtistRole
) => {
  const artists = Array.isArray(trackOrArtists) ? trackOrArtists : trackOrArtists.artists;

  const artistForRole = artists.filter(a => a.roles.find(r => r.name === roleName));

  // ensure artists are sorted by their artist role order
  return _.sortBy(
    artistForRole,
    artist => artist.roles.find(r => r.name === roleName)!.order
  );
};

// returns track artists in preferred order
const isOriginal = (artist: AlgoliaArtist) =>
  artist.roles.find(r => r.name === ARTIST_ROLES.ORIGINAL);

// Ensure original artists are in the front of the array
export const getOrderedTrackArtists = (artists: AlgoliaArtist[]) =>
  artists.sort((a, b) => {
    // We may add additional sort criteria in the future, so nesting criteria within object
    const artistA = {
      original: isOriginal(a)
    };

    const artistB = {
      original: isOriginal(b)
    };

    if (artistA.original && !artistB.original) return -1;
    if (!artistA.original && artistB.original) return 1;
    return 0;
  });

/*
 * [getDownloadableTracksByCategory] - For the given user and tracks, returns an object with
 * the tracks by id that can be downloaded across various categories
 *
 * NOTE: You probably want to use the selector, 'makeSelectUserDownloadableTracksByCategory'
 *
 * @param {string} tracks - tracks that you want to check against
 * @param {string} user
 * @param {number[]} promoTracksIds - array of all trackIds that are on promo
 * @returns {object}
 */

export const getDownloadableTracksByCategory = ({
  tracks,
  user,
  promoTracksIds
}: {
  tracks: Audio[];
  user: V2ProUser;
  promoTracksIds: number[];
}) => {
  const userArtistsById = getUserProfilesById(user, 'artist');
  const userLabelsById = getUserProfilesById(user, 'label');
  const isAdmin = isAdminUser(user);

  return tracks.reduce(
    (accum, track) => {
      const trackHasUserArtist = track.artists.find(artist => userArtistsById[artist.id]);
      const trackHasUserLabel = userLabelsById[track.label.id];
      const userHasPromoAccess = promoTracksIds.includes(track.id);
      const userHasArchiveAccess = track.hasArchiveAccess;
      const canDownload =
        trackHasUserArtist ||
        trackHasUserLabel ||
        userHasPromoAccess ||
        userHasArchiveAccess ||
        isAdmin;

      return {
        all: {
          ...accum.all,
          ...(canDownload ? { [track.id]: track } : {})
        },
        artist: {
          ...accum.artist,
          ...(trackHasUserArtist ? { [track.id]: track } : {})
        },
        label: {
          ...accum.label,
          ...(trackHasUserLabel ? { [track.id]: track } : {})
        },
        promo: {
          ...accum.promo,
          ...(userHasPromoAccess ? { [track.id]: track } : {})
        }
      };
    },
    { all: {}, artist: {}, label: {}, promo: {} }
  );
};

/**
 * [isTrackDownloadable] - determine if passed audio is a track that is downloadable for the passed user
 *
 */

export const isTrackDownloadable = (isPromo: boolean, audio: Audio, user: V2ProUser) => {
  if (audio.__audioType !== AUDIO_TYPES.TRACK) return false;
  if (isPromo) return true;
  if (audio.hasArchiveAccess) return true;

  // Check if track is available via artist or labels the user manages
  const isAdmin = isAdminUser(user);
  const userArtistsById = getUserProfilesById(user, 'artist');
  const userLabelsById = getUserProfilesById(user, 'label');

  const trackHasUserArtist = !!audio.artists.find(artist => userArtistsById[artist.id]);
  const trackHasUserLabel = !!userLabelsById[audio.label.id];

  return isAdmin || trackHasUserArtist || trackHasUserLabel;
};

const isTrackAvailableForPremiumDownload = ({
  track,
  user
}: {
  track: Audio;
  user: V2ProUser;
}) => {
  if (track.__audioType !== AUDIO_TYPES.TRACK) return false;

  const isAdmin = isAdminUser(user);
  const userArtistsById = getUserProfilesById(user, 'artist');
  const userLabelsById = getUserProfilesById(user, 'label');

  const userIsMemberOfTrackArtists = !!track.artists.find(
    artist => userArtistsById[artist.id]
  );
  const userManagesTrackLabel = !!userLabelsById[track.label.id];

  const trackIsOwnedByUser = userIsMemberOfTrackArtists || userManagesTrackLabel;

  return trackIsOwnedByUser || isAdmin;
};

export const getDownloadFileTypeForTrack = ({
  track,
  user,
  preferredFileType
}: {
  track: Audio;
  user: V2ProUser;
  preferredFileType?: DownloadFileType;
}) => {
  const isPremiumDownloadAvailable = isTrackAvailableForPremiumDownload({ track, user });
  const defaultFileType = AUDIO_FILE_TYPES.MP3.VALUE;

  return isPremiumDownloadAvailable
    ? preferredFileType || defaultFileType
    : defaultFileType;
};

export const canDownloadLossless = (
  currentUser: User,
  { track, user }: { track: Audio; user: V2ProUser }
) =>
  currentUser.roles.curator ||
  currentUser.roles.admin ||
  isTrackAvailableForPremiumDownload({ track, user });

interface TrackDownloadConfig {
  track: Audio;
  user: V2ProUser;
  format?: DownloadFileType;
}

export const trackDownloadConfig = (
  currentUser: User,
  { track, user, format }: TrackDownloadConfig
) => ({
  track,
  filetype: canDownloadLossless(currentUser, { track, user })
    ? format || currentUser.settings['DOWNLOAD_FORMAT'].value
    : AUDIO_FILE_TYPES.MP3.VALUE,
  location: currentUser.settings['DOWNLOAD_LOCATION'].value
});
