import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ActionMenuItem, FilePickerActionMenu } from 'components/ActionMenu';
import Icon from 'components/Icon';
import { ChevronIcon } from 'components/Icons';
import useToggle from 'hooks/useToggle';
import Badge from 'components/Badge';

import {
  getDownloadLocationOptions,
  getCurrentDownloadLocationOption
} from 'helpers/downloadLocation';

import { downloadTrack as downloadTrackAction } from 'redux/actions/tracks';

import { COLORS } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers';
import useCurrentUser from 'hooks/useCurrentUser';
import useMutationWithAlert from 'hooks/useMutationWithAlert';
import { trackDownloadConfig } from 'helpers/tracks';

const UpdateUserSettingsMutation = `
  mutation updateUserSettings ($input: UserSettingUpdateInput!) {
    userSettingUpdate (input: $input) {
      errors
      userSetting {
        enabled
        settingType
        value
      }
    }
  }
`;

const SelectFileType = styled.div.attrs(() => ({
  role: 'button',
  tabIndex: '0'
}))`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 2rem;
  position: absolute;
  right: 0;
  top: 0;
`;

const DownloadTrackActionItem = ({ track, closeParentMenu, closeOnNavigation }) => {
  const [isDownloading, setDownloading] = useState(false);

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [, updateUserSettings] = useMutationWithAlert(UpdateUserSettingsMutation);

  const {
    isOpen: isDownloadFileMenuOpen,
    open: openDownloadFileMenu,
    close: closeDownloadFileMenu
  } = useToggle();

  const history = useHistory();
  const { user: currentUser } = useCurrentUser();

  const downloadLocationOptions = getDownloadLocationOptions(user);
  const currentLocationOption = getCurrentDownloadLocationOption(currentUser, user);
  const trackDownloadSettings = trackDownloadConfig(currentUser, { track, user });

  const handleDownloadTrack = () => {
    setDownloading(true);

    dispatch(
      downloadTrackAction.call(trackDownloadConfig(currentUser, { track, user }), {
        onFinally: () => {
          setDownloading(false);
          closeParentMenu();
        }
      })
    );
  };

  const handleSettingsUpdate = (settingType, value) => {
    if (!value) {
      history.push('/account/promo-pool');
      return;
    }

    updateUserSettings({
      input: {
        userId: currentUser.id,
        settingType,
        value,
        enabled: true
      }
    });
  };

  return (
    <>
      <ActionMenuItem
        icon={<Icon type={Icon.TYPES.DOWNLOAD} width={20} />}
        onClick={handleDownloadTrack}
        disabled={isDownloading}
        data-testid={DownloadTrackActionItem.TEST_IDS.BUTTON}
        closeMenu={close}
      >
        <div>{isDownloading ? 'Downloading...' : 'Download'}</div>
        <SelectFileType
          onClick={e => {
            e.stopPropagation();
            openDownloadFileMenu();
          }}
          data-testid={DownloadTrackActionItem.TEST_IDS.FILE_PICKER}
        >
          <Badge noMargin>
            {`${String(trackDownloadSettings.filetype).toUpperCase()} `}
            <ChevronIcon
              direction={ChevronIcon.DIRECTIONS.DOWN}
              size={6}
              color={COLORS.GRAY_DARK}
            />
          </Badge>
        </SelectFileType>
      </ActionMenuItem>

      {/* TRACK DOWNLOAD FILE SELECT MENU */}
      <FilePickerActionMenu
        isOpen={isDownloadFileMenuOpen}
        close={closeDownloadFileMenu}
        closeOnNavigation={closeOnNavigation}
        handleSettingsUpdate={handleSettingsUpdate}
        downloadLocationOptions={downloadLocationOptions}
        currentLocationOption={currentLocationOption}
        track={track}
      />
    </>
  );
};

DownloadTrackActionItem.TEST_IDS = {
  BUTTON: 'DownloadTrackActionItem-react-download-track-action-item',
  FILE_PICKER: 'DownloadTrackActionItem-file-picker'
};

DownloadTrackActionItem.SELECTORS = generateSelectorsFromTestIds(
  DownloadTrackActionItem.TEST_IDS
);

DownloadTrackActionItem.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number
  }),
  closeParentMenu: PropTypes.func,
  closeOnNavigation: PropTypes.bool
};

export default DownloadTrackActionItem;
