import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import ActionMenu from 'components/ActionMenu/ActionMenu';
import ActionMenuItem from 'components/ActionMenu/ActionMenuItem';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';

import TextHeader from 'components/TextHeader';
import { COLORS, AUDIO_FILE_TYPES } from 'config/constants';
import ENTITY_PROPTYPES from 'config/constants/propTypes';
import { generateSelectorsFromTestIds } from 'helpers';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { canDownloadLossless } from '../../../helpers/tracks';

const TextHeaderWrapper = styled.div`
  margin-bottom: -0.5rem;
  padding: 1.5rem 1.5rem 0 1.5rem;
`;

const Divider = styled.div`
  background: #ddd;
  height: 0.1rem;
  margin: 1rem 0;
  width: 100%;
`;

const TextHighlighter = styled.span`
  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    font-weight: 500;
    color: ${COLORS.PRIMARY};
  `}
  ${({ isDisabled }) =>
    isDisabled &&
    `
    color: ${COLORS.GRAY_LIGHT};
    text-decoration: line-through;
  `}
`;

const FilePickerActionMenu = ({
  track,
  isOpen,
  close,
  closeOnNavigation,
  downloadLocationOptions,
  currentLocationOption,
  handleSettingsUpdate
}) => {
  const { user: currentUser } = useCurrentUser();
  const user = useSelector(state => state.user);
  const hasPremiumDownloads = canDownloadLossless(currentUser, { user, track });
  const preferredAudioDownloadType = currentUser.settings['DOWNLOAD_FORMAT'].value;

  return (
    <ActionMenu
      isOpen={isOpen}
      close={close}
      closeOnNavigation={closeOnNavigation}
      testId={FilePickerActionMenu.TEST_IDS.CONTAINER}
    >
      <TextHeaderWrapper>
        <TextHeader type={TextHeader.TYPES.SMALL} weight={TextHeader.WEIGHTS.HEAVY}>
          File Type
        </TextHeader>
      </TextHeaderWrapper>
      <ActionMenuList>
        {Object.values(AUDIO_FILE_TYPES).map(({ LABEL, VALUE, PREMIUM }) => {
          const isDisabled = !hasPremiumDownloads && PREMIUM;
          const isHighlighted = !hasPremiumDownloads
            ? VALUE === 'mp3'
            : preferredAudioDownloadType === VALUE;

          return (
            <ActionMenuItem
              key={`DownloadTypes:${VALUE}`}
              testId={FilePickerActionMenu.TEST_IDS.getTestIdForFileType(VALUE)}
              disabled={isDisabled}
              onClick={() => {
                handleSettingsUpdate('DOWNLOAD_FORMAT', VALUE);
                // Short delay so users see their selection get highlighted before closing
                setTimeout(() => close(), 200);
              }}
              closeMenu={close}
            >
              <TextHighlighter isHighlighted={isHighlighted} isDisabled={isDisabled}>
                {LABEL}
              </TextHighlighter>
            </ActionMenuItem>
          );
        })}
      </ActionMenuList>

      <Divider />

      <TextHeaderWrapper>
        <TextHeader type={TextHeader.TYPES.SMALL}>Download Location</TextHeader>
      </TextHeaderWrapper>
      <ActionMenuList>
        {downloadLocationOptions.map(({ label, value, key }) => (
          <ActionMenuItem
            key={`DownloadLocations:${value || key}`}
            testId={FilePickerActionMenu.TEST_IDS.getTestIdForDownloadType(label)}
            onClick={() => {
              handleSettingsUpdate('DOWNLOAD_LOCATION', value);
              setTimeout(() => close(), 200);
            }}
            closeMenu={close}
          >
            <TextHighlighter isHighlighted={currentLocationOption.value === value}>
              {label}
            </TextHighlighter>
          </ActionMenuItem>
        ))}
      </ActionMenuList>
    </ActionMenu>
  );
};

const getTestIdForFileType = value =>
  `FilePickerActionMenu-fileType-${_.kebabCase(value)}`;
const getTestIdForDownloadType = value =>
  `FilePickerActionMenu-downloadType-${_.kebabCase(value)}`;

FilePickerActionMenu.TEST_IDS = {
  CONTAINER: 'FilePickerActionMenu',
  WAV: getTestIdForFileType('wav'),
  MP3: getTestIdForFileType('mp3'),
  AIFF: getTestIdForFileType('aiff'),
  getTestIdForFileType,
  DROPBOX: getTestIdForDownloadType('dropbox'),
  DIRECT_DOWNLOAD: getTestIdForDownloadType('direct-download'),
  getTestIdForDownloadType
};

FilePickerActionMenu.SELECTORS = generateSelectorsFromTestIds(
  FilePickerActionMenu.TEST_IDS
);

FilePickerActionMenu.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  closeOnNavigation: PropTypes.bool,
  downloadLocationOptions: PropTypes.arrayOf(PropTypes.shape({})),
  currentLocationOption: PropTypes.shape(),
  handleDownloadUpdate: PropTypes.func,
  track: ENTITY_PROPTYPES.TRACK
};

export default FilePickerActionMenu;
