import PropTypes from 'prop-types';
import React from 'react';

import { ActionMenuHeader, TrackHeader } from 'components/ActionMenu';
import Well from 'components/Well';
import { EXTERNAL_LINKS } from 'config/constants';

const TrackMenuHeader = ({ children, track, showCompile, isPromo }) => (
  <ActionMenuHeader noUnderline={isPromo}>
    <TrackHeader {...track} />

    {children}

    {showCompile && track.no_compile && (
      <Well size={Well.SIZES.COMPACT} align="flex-start" className="mt-3">
        <p className="mb-1">
          This track is locked and requires permission to play in DJ mixes distributed to
          Spotify and Apple Music.
        </p>
        <a
          href={EXTERNAL_LINKS.INTERCOM.LOCKED_TRACKS}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </Well>
    )}
  </ActionMenuHeader>
);

TrackMenuHeader.propTypes = {
  children: PropTypes.node,
  showCompile: PropTypes.bool,
  track: PropTypes.shape(),
  isPromo: PropTypes.bool
};

export default TrackMenuHeader;
