import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import useProtonPlayer from 'hooks/useProtonPlayer';

const getVolumeClass = volume => {
  if (volume === 0 || volume === '0') {
    return 'is-muted';
  } else if (volume > 0 && volume <= 0.33) {
    return 'is-volume-1';
  } else if (volume > 0.33 && volume <= 0.66) {
    return 'is-volume-2';
  } else if (volume > 0.66) {
    return 'is-volume-3';
  }
  return '';
};

const PlayerVolume = () => {
  const player = useProtonPlayer();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [boundingRect, setBoundingRect] = useState(null);

  const updateVolume = mouseY => {
    if (!boundingRect) return;

    const containerHeight = 85;
    const { bottom } = boundingRect;

    let volume;

    if (mouseY < bottom - containerHeight) {
      volume = 1;
    } else if (mouseY > bottom) {
      volume = 0;
    } else {
      volume = (bottom - mouseY) / containerHeight;
    }

    // Return so we avoid re-rendering
    // the same value repeatedly.
    if (volume < 0 || volume > 1) return;

    player.send('setVolume', volume);
  };

  const handleMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();

    const mouseY = e.clientY;

    setIsMouseDown(true);
    setBoundingRect(e.target.getBoundingClientRect());
    setTimeout(() => updateVolume(mouseY), 0);
  };

  const toggleMute = e => {
    e.preventDefault();
    player.send('toggleMute');
  };

  useEffect(() => {
    const handleMouseUp = () => setIsMouseDown(false);
    const handleMouseMove = e => {
      if (!isMouseDown) return;

      const mouseY = e.clientY;
      updateVolume(mouseY);
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  });

  const volumeStyle = {
    height: 85 * player.volume
  };

  const volumeClasses = classnames('player__volume-icon', getVolumeClass(player.volume));

  return (
    <div className="player__volume-trigger">
      <div role="button" tabIndex="0" className={volumeClasses} onClick={toggleMute}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="20px"
          height="20px"
          viewBox="0 0 72 72"
          enableBackground="new 0 0 72 72"
          xmlSpace="preserve"
        >
          <g>
            <path
              className="volume-3"
              fill="#FFFFFF"
              d="M62.837,10.311c-0.823-1.194-2.458-1.498-3.655-0.675c-1.195,0.823-1.497,2.459-0.674,3.655
                   c4.621,6.717,7.065,14.588,7.065,22.76c0,8.614-2.691,16.833-7.782,23.766c-0.859,1.169-0.607,2.814,0.562,3.673
                   c0.47,0.345,1.015,0.511,1.555,0.511c0.808,0,1.606-0.372,2.119-1.072c5.76-7.843,8.804-17.136,8.804-26.877
                   C70.83,26.81,68.066,17.909,62.837,10.311z"
            />
            <path
              fill="#FFFFFF"
              d="M38.805,9.582c-0.963-0.436-2.089-0.271-2.884,0.423L20.512,23.468H3.607c-1.486,0-2.691,1.205-2.691,2.691
                   v20.855c0,1.487,1.205,2.69,2.691,2.69h16.905l15.409,13.464c0.5,0.438,1.132,0.664,1.771,0.664c0.377,0,0.756-0.077,1.112-0.24
                   c0.961-0.437,1.578-1.395,1.578-2.451v-49.11C40.383,10.977,39.766,10.018,38.805,9.582z"
            />
            <path
              className="volume-2"
              fill="#FFFFFF"
              d="M55.659,16.786c-0.821-1.195-2.457-1.498-3.655-0.675c-1.195,0.823-1.498,2.46-0.675,3.656
                   c3.336,4.847,5.1,10.526,5.1,16.424c0,6.218-1.942,12.147-5.616,17.15c-0.859,1.17-0.607,2.815,0.562,3.674
                   c0.47,0.345,1.015,0.511,1.555,0.511c0.808,0,1.604-0.373,2.119-1.073c4.342-5.912,6.636-12.918,6.636-20.262
                   C61.684,29.225,59.601,22.514,55.659,16.786z"
            />
            <path
              className="volume-1"
              fill="#FFFFFF"
              d="M48.736,23.403c-0.746-1.245-2.36-1.649-3.605-0.901c-1.245,0.747-1.647,2.362-0.9,3.606
                   c1.82,3.031,2.781,6.512,2.781,10.064c0,3.857-1.121,7.588-3.243,10.795c-0.801,1.211-0.469,2.841,0.741,3.643
                   c0.447,0.295,0.949,0.437,1.447,0.437c0.854,0,1.689-0.413,2.195-1.179c2.692-4.069,4.116-8.806,4.116-13.696
                   C52.269,31.667,51.048,27.252,48.736,23.403z"
            />
          </g>
          <polygon
            className="volume-0"
            fill="#FFFFFF"
            points="72,28.608 67.123,23.732 59.143,31.713 51.16,23.732 46.284,28.608 54.265,36.59 46.284,44.571
                      51.16,49.447 59.143,41.466 67.123,49.447 72,44.571 64.018,36.59"
          />
        </svg>
      </div>
      <div className="player__volume-container">
        <div
          role="button"
          tabIndex="0"
          className="player__volume-inner"
          onMouseDown={handleMouseDown}
        >
          <div className="player__volume-level" style={volumeStyle} />
        </div>
      </div>
    </div>
  );
};

export default PlayerVolume;
