import React from 'react';
import { Link } from 'react-router-dom';
import { useField, useFormikContext } from 'formik';
import styled from 'styled-components';

import { DangerIcon } from 'components/Icons';
import { BREAKPOINTS } from 'config/constants';
import { InputHTMLAttributes } from 'react';

const TextInput_Container = styled.div`
  position: relative;
  height: 52px;
  width: 100%;
  margin: 36px 0;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    height: 50px;
    margin: 32px 0;
  }
`;

const TextInput_Label = styled.label<{ error?: unknown }>`
  position: absolute;
  top: 16px;
  left: 12px;
  font-size: 18px;
  color: ${({ error }) => (error ? '#e33939' : '#999')};
  transition: transform 0.5s, top 0.5s;
  pointer-events: none;
  transform-origin: left;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    color: #fff;
  }
`;

const TextInput_Input = styled.input<{ $error: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 12px;
  padding-top: 27px;
  outline: none;
  font-size: 18px;
  transition: box-shadow 0.5s;

  &:focus {
    border-color: #e26014;
  }

  &:focus + ${TextInput_Label} {
    top: 7px;
    transform: scale(0.7);
    color: #e26014;
  }

  border-color: ${({ $error }) => ($error ? '#e33939' : undefined)};
  box-shadow: ${({ $error }) => ($error ? '0 0 3px #e33939' : undefined)};

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    border-color: ${({ $error }) => ($error ? '#e33939' : 'rgba(255, 255, 255, 0.7)')};
    color: #fff;
    background: none;
    box-shadow: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-internal-autofill-previewed {
      -webkit-text-fill-color: white !important;
      -webkit-background-clip: text;
    }
  }
`;

const TextInput_ErrorMessage = styled.div`
  color: #e33939;
  padding: 8px 12px;
  font-size: 14px;
`;

const TextInput_Forgot = styled(Link)`
  position: absolute;
  right: 12px;
  top: 14px;
  border: 1px solid #ccc;
  color: #ccc;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  transition: color 0.5s, border-color 0.5s;

  &:active {
    outline: none;
  }

  &:hover {
    color: #e26014;
    border-color: #e26014;
    cursor: pointer;
    text-decoration: none;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    border-color: rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.7);
  }
`;

TextInput_Forgot.defaultProps = {
  tabIndex: -1
};

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'password' | 'email';
  name: string;
  label?: string;
  onForgot?: string;
  error?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  children,
  onForgot,
  error,
  ...props
}) => {
  const [field, meta] = useField<string>(props);

  // The label shrinks to the upper-left of the form field when focused or when
  // the field's value is not empty.
  const isNotEmpty = !!field.value;
  const labelTransform = isNotEmpty ? { top: 7, transform: 'scale(0.7)' } : {};

  // Only consider the field to be in an error state if it has been interacted
  // with already.
  const errorMessage = meta.error || error;
  const isErrorState = meta.touched && !!errorMessage;

  return (
    <TextInput_Container>
      <TextInput_Input
        {...field}
        {...props}
        $error={isErrorState}
        type={props.type || 'text'}
      />
      <TextInput_Label htmlFor={props.id || props.name} style={labelTransform}>
        {label}
      </TextInput_Label>
      {isErrorState ? (
        <TextInput_ErrorMessage>{errorMessage}</TextInput_ErrorMessage>
      ) : null}
      {onForgot && (
        <TextInput_Forgot to={{ pathname: onForgot, state: { modal: true } }}>
          Forgot?
        </TextInput_Forgot>
      )}
      {children}
    </TextInput_Container>
  );
};

interface CheckboxStyleProps {
  error?: string;
  whiteOnMobile?: boolean;
}

const Checkbox_Container = styled.label<CheckboxStyleProps>`
  color: #7d7d7d;
  cursor: pointer;
  font-size: 20px;
  user-select: none;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    color: ${props => (props.whiteOnMobile ? 'white' : '#7d7d7d')};
    font-size: 14px;
  }
`;

const Checkbox_Hidden = styled.input<CheckboxStyleProps>`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;

  &:checked + * {
    color: white;
    background-color: #e26014;
    border-color: #e26014;

    @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
      ${props =>
        props.whiteOnMobile
          ? `
        background: transparent;
        border-color: white;
      `
          : ''}
    }
  }

  &:focus + * {
    border-color: #e26014;
  }
`;

const Checkbox_View = styled.div<CheckboxStyleProps>`
  border-radius: 4px;
  display: inline-block;
  height: 18px;
  width: 18px;
  background: #fff;
  border: 1px solid #ccc;
  border-color: ${props => (props.error ? '#e33939' : '#ccc')};
  color: rgba(0, 0, 0, 0);
  text-align: center;
  padding: 2px 0;
  font-size: 15px;
  transition: border-color 0.5s, background 0.5s, color 0.5s;

  &:hover {
    border-color: #e26014;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    background: transparent;
  }
`;

const CheckboxInput_Container = styled.div<CheckboxStyleProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    ${props =>
      props.whiteOnMobile
        ? `
      padding-top: 4px;
      justify-content: center;
      `
        : ''}
  }
`;

const CheckboxInput_Message = styled.div`
  padding: 0 12px;
  user-select: none;
  line-height: 16px;
`;

interface CheckboxInputProps
  extends CheckboxStyleProps,
    InputHTMLAttributes<HTMLInputElement> {
  type?: 'checkbox';
  name: string;
  label?: string;
}

export const CheckboxInput: React.FC<CheckboxInputProps> = props => {
  const [field, meta, helpers] = useField({ ...props, type: 'checkbox' });

  const isErrorState = meta.touched ? meta.error : undefined;

  const toggleValue = () => helpers.setValue(!field.value);

  return (
    <CheckboxInput_Container whiteOnMobile={props.whiteOnMobile} style={props.style}>
      <Checkbox_Container whiteOnMobile={props.whiteOnMobile}>
        <Checkbox_Hidden type="checkbox" tabIndex={0} {...field} {...props} />
        <Checkbox_View error={isErrorState}>✓</Checkbox_View>
      </Checkbox_Container>
      <CheckboxInput_Message onClick={toggleValue}>
        {props.label}
        {isErrorState && (
          <DangerIcon size={12} style={{ marginLeft: 8, top: 2, position: 'relative' }} />
        )}
      </CheckboxInput_Message>
    </CheckboxInput_Container>
  );
};

const SubmitButton_Container = styled.button<{ danger: boolean }>`
  width: 100%;
  background-color: ${({ danger }) => (danger ? '#e33939' : '#e26014')};
  outline: none;
  height: 52px;
  border-radius: 4px;
  font-weight: 700;
  margin-top: 24px;

  &:hover {
    background-color: #dd804a;
    transition: background-color 0.5s;
  }
`;

export const SubmitButton = props => {
  const formik = useFormikContext();

  return <SubmitButton_Container disabled={formik.isSubmitting} {...props} />;
};
