import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Pacman from 'components/Icons/Pacman';

const StyledLoader = styled.div`
  align-items: center;
  display: flex;
  background: ${({ $overlay }) => ($overlay ? 'rgba(255, 255, 255, 0.85)' : '#ffffff')};
  backdrop-filter: ${({ $overlay }) => ($overlay ? 'blur(8px)' : 'none')};
  display: flex;
  justify-content: center;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${({ $overlay }) => ($overlay ? '1500' : '3')};
`;

const Loader = ({ overlay = false, testId }) => (
  <StyledLoader $overlay={overlay} data-testid={testId}>
    <Loader.Icon />
  </StyledLoader>
);

// Static defined so loader icon can be used with one-off styling easier
Loader.Icon = Pacman;

Loader.propTypes = {
  overlay: PropTypes.bool,
  testId: PropTypes.string
};

export default Loader;
