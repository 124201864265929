import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import ActionMenu, {
  ActionMenuHeader,
  ActionMenuItem,
  ActionMenuList
} from 'components/ActionMenu';
import Flex from 'components/Flex';
import { CameraIcon } from 'components/Icons';
import TextHeader from 'components/TextHeader';
import { COLORS, ENTITY_TYPES } from 'config/constants';
import useToggle from 'hooks/useToggle';

const Container = styled.div<{ $isRound: boolean }>`
  position: absolute;
  height: 100%;
  overflow: hidden;
  top: 0;
  width: 100%;

  ${({ $isRound }) =>
    $isRound
      ? `border-radius: 50%;`
      : `
    &:hover {
      cursor: pointer;
    }
  `}
`;

export const Banner = styled.div<{ $isExpanded: boolean }>`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  bottom: -1px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.15s ease-in-out;

  ${({ $isExpanded }) =>
    $isExpanded &&
    `
    height: calc(100% + 2px);
  `}
`;

const CircleTrigger = styled.svg`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;

  circle:hover {
    cursor: pointer;
  }
`;

const ImageUploadBanner = ({ entityType, onImageUpload, onImageDelete }) => {
  const { isOpen, open, close } = useToggle();
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const isRoundImage = entityType === ENTITY_TYPES.ARTIST;

  const triggerProps = {
    onClick: open,
    onMouseEnter: () => setIsExpanded(true),
    onMouseLeave: () => setIsExpanded(false)
  };

  return (
    <Container $isRound={isRoundImage} {...(isRoundImage ? {} : triggerProps)}>
      {/* The hover region on circles only works for svg, otherwise content that overflows (even if hidden) respects 
          hover rules */}
      {isRoundImage && (
        <CircleTrigger viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet">
          <circle r="50%" cx="50%" cy="50%" fill="transparent" {...triggerProps} />
        </CircleTrigger>
      )}

      <Banner onClick={open} $isExpanded={isExpanded}>
        <Flex justify="center">
          <CameraIcon color="currentColor" className="mr-1" />
          <TextHeader
            data-testid={ImageUploadBanner.TEST_ID.UPLOAD_TOGGLE}
            type={TextHeader.TYPES.SMALL}
            color={TextHeader.COLORS.LIGHT}
            weight={TextHeader.WEIGHTS.NORMAL}
            uppercase
          >
            Edit
          </TextHeader>
        </Flex>

        <input
          ref={fileUploadRef}
          style={{ display: 'none' }}
          type="file"
          accept=".jpg, .jpeg, .png"
          onChange={event => {
            if (!event.target.files || event.target.files.length !== 1) {
              return;
            }

            setIsUploading(true);
            return onImageUpload(event.target.files[0]).finally(() => {
              setIsUploading(false);
              close();
            });
          }}
          disabled={isUploading}
        />
      </Banner>

      <ActionMenu
        isOpen={isOpen}
        close={close}
        disabled={isUploading || isDeleting}
        closeOnNavigation={false}
      >
        <ActionMenuHeader>
          <TextHeader
            type={TextHeader.TYPES.MEDIUM}
            size={TextHeader.TYPES.MEDIUM}
            className="mb-0"
          >
            Change {_capitalize(entityType)} Photo
          </TextHeader>
        </ActionMenuHeader>

        <ActionMenuList closeMenu={close}>
          <ActionMenuItem
            disabled={isUploading || isDeleting}
            testId={ImageUploadBanner.TEST_ID.UPLOAD_BUTTON}
            onClick={() => {
              fileUploadRef.current?.click();
            }}
          >
            {isUploading ? 'Uploading...' : 'Upload Photo'}
          </ActionMenuItem>

          {onImageDelete && (
            <ActionMenuItem
              disabled={isUploading || isDeleting}
              testId={ImageUploadBanner.TEST_ID.DELETE_BUTTON}
              onClick={() => {
                setIsDeleting(true);

                return onImageDelete().finally(() => {
                  setIsDeleting(false);
                  close();
                });
              }}
              closeMenu={close}
              style={{ color: COLORS.DANGER }}
            >
              {isDeleting ? 'Deleting...' : 'Delete Photo'}
            </ActionMenuItem>
          )}
        </ActionMenuList>
      </ActionMenu>
    </Container>
  );
};

ImageUploadBanner.TEST_ID = {
  UPLOAD_TOGGLE: 'ImageUploadBanner--toggle',
  UPLOAD_BUTTON: 'ImageUploadBanner--upload-btn',
  DELETE_BUTTON: 'ImageUploadBanner--delete-btn'
};

ImageUploadBanner.propTypes = {
  entityType: PropTypes.string,
  onImageUpload: PropTypes.func,
  onImageDelete: PropTypes.func
};

export default ImageUploadBanner;
