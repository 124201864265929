import { createLogger } from 'redux-logger';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { algoliaApi, protonApi } from 'api/rtkSetup';
import reducers from 'redux/reducers';
import rootSaga from 'redux/sagas';

import { isProduction } from './constants';
import migrations, { rootMigrations } from './migrations';
import { logError } from 'helpers/logError';

// NOTE: 'configureStore' adds redux-thunk middleware by default
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, protonApi.middleware, algoliaApi.middleware];

if (
  // We want to ensure logging is not set up in unit test environment
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'staging'
) {
  // logger action types to keep collapsed for cleaner console debugging
  const LOGGER_COLLAPSE_TYPES = [
    'GARBAGE_COLLECTOR',
    'RADIO_LISTENERS_SET',
    'RADIO_FETCHING',
    'RADIO_FETCH_SUCCESS',
    '__rtkq/focused'
  ];

  const LOGGER_MUTED_TYPES = ['PLAYER_POSITION_SET'];

  const MUTED_ACTION_WARNINGS = {}; // which muted actions have already notified the client

  const LOGGER_COLLAPSE_PARTIAL_TYPES = ['persist/', '@@redux-form/'];

  middlewares.push(
    createLogger({
      predicate: (_, { type }: { type: string }) => {
        if (LOGGER_MUTED_TYPES.includes(type)) {
          // The first time a muted action is dispatched, lets notify the console that
          // these actions are being muted
          if (!MUTED_ACTION_WARNINGS[type]) {
            console.warn(
              `[Redux Logger] Actions with type '${type}' are muted. You can configure this in config/store.js`
            );
            MUTED_ACTION_WARNINGS[type] = true;
          }
          return false;
        }
        return true;
      },
      collapsed: (_, { type }: { type: string }) => {
        if (LOGGER_COLLAPSE_TYPES.includes(type)) return true;
        const partialMatch = LOGGER_COLLAPSE_PARTIAL_TYPES.find(partialType =>
          type.includes(partialType)
        );
        if (partialMatch) return true;

        return false;
      }
    })
  );
}

// automatically set migration version number to latest so we don't forget to set it
const rootMigrationNumber = Object.keys(rootMigrations).length;

const rootPersistConfig = {
  key: 'root',
  version: rootMigrationNumber,
  storage,
  whitelist: [
    'features',
    'followed', // TODO: Remove after API is working again
    'mixes',
    'promoReleases',
    'radio',
    'tracks',
    'uiPersist',
    'user'
  ],
  migrate: migrations.root
};

// In order to clear the reducer with one action, we created a parent "appReducer"
// rootReducer merely passes off everything to appReducer, except when RESET_STORE action
// is fired.

// See https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const appReducer = combineReducers(reducers);

// type Reducer<S = any, A extends Action<any> = AnyAction> = (state: S, action: A) => S
// const rootReducer = (state: any, action: Action): Reducer<any, Action> => {
const rootReducer = (state: RootState, action: Action): RootState => {
  if (action.type === 'RESET_STORE') {
    // for all keys defined in your persistConfig(s), clear local storage items
    storage.removeItem('persist:root').catch(logError);

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(middlewares)
});

export type AppStore = typeof store;

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

declare global {
  interface Window {
    __store__: typeof store;
  }
}

// Set store on window if running tests.  Tests run with env: testing in circle cli, but run against development locally
if (!isProduction) window.__store__ = store;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
