import PropTypes from 'prop-types';
import React from 'react';

import useBreakpoint from 'hooks/useBreakpoint';
import useToggle from 'hooks/useToggle';

import Popover from 'components/Popover';

/**
 * [MenuToggler] - Wrapper that handles render logic for showing action menu vs dropdown
 * NOTE: This component is largely deprecated. We should handle this functionality by breaking up mobile
 * and desktop functionality into separate components.
 *
 * @param {func} renderActionMenu - return the ActionMenu to be rendered
 * @param {func} renderPopoverContent - return the content to be rendered with the popover
 * @param {func} renderToggle - return button to be rendered that will toggle the action menu
 * or the popover to be displayed.
 * @param {func} [onOpen] - passed to popover, run when popover is opened
 * @param {func} [onClose] - passed to popover, run when popover is closed
 * @param {string} [renderDirection] - passed to popover, primary render direction for popover
 * @param {string} [fixedPopover] - passed to popover, renders popover with fixed positioning
 */

const MenuToggler = React.memo(function MenuToggler(props) {
  const {
    renderActionMenu,
    renderPopoverContent,
    forceAcionMenuDisplay,
    renderToggle,
    fixedPopover,
    renderDirection = Popover.DIRECTIONS.DOWN,
    onOpen,
    onClose
  } = props;

  const shouldDisplayActionMenu =
    useBreakpoint(useBreakpoint.BREAKPOINTS.SMALL, useBreakpoint.DIRECTIONS.DOWN) ||
    forceAcionMenuDisplay;

  const toggleProps = useToggle();
  const { isOpen, open, close, toggle } = toggleProps;

  if (shouldDisplayActionMenu) {
    return (
      <>
        {renderToggle && renderToggle({ open, close, isOpen, toggle, actionMenu: true })}
        {renderActionMenu({ open, close, isOpen, toggle })}
      </>
    );
  }

  return (
    <Popover
      onOpen={onOpen}
      onClose={onClose}
      renderToggle={renderToggle ? toggleProps => renderToggle(toggleProps) : null}
      renderDirection={renderDirection}
      fixed={fixedPopover}
    >
      {popoverProps => renderPopoverContent(popoverProps)}
    </Popover>
  );
});

MenuToggler.propTypes = {
  renderActionMenu: PropTypes.func,
  forceAcionMenuRender: PropTypes.bool,
  renderPopoverContent: PropTypes.func,
  renderToggle: PropTypes.func,
  renderDirection: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixedPopover: PropTypes.bool
};

export default MenuToggler;
