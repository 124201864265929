import styled from 'styled-components';

import { MEDIUM_BREAK_POINT, COLORS } from 'config/constants';

export const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.4rem;
  color: ${COLORS.GRAY_MEDIUM};
  min-width: 40rem;
  max-width: 42rem;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 0 0 1.5rem 0;
    min-width: initial;
    max-width: 55rem;
  }
`;

export const Row = styled.div`
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const ArtistPickerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem 1.5rem 0 1.5rem;
`;

export const ActionsButtonGroup = styled.div`
  display: flex;
  flex: 1 0 auto;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const RatingContainer = styled.div`
  align-items: center;
  border-right: 1px solid #ddd;
  display: flex;
  flex: 0 0 auto;
  padding: 0 4rem 0 2rem;
  max-width: 170px;
  width: 100%;

  @media screen and (max-width: ${MEDIUM_BREAK_POINT}px) {
    padding: 0 2rem 0 1rem;
  }
`;

export const RatingContainerInner = styled.div`
  padding: 0.5rem 0;
  width: 100%;
`;

export const StoreLinks = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
`;
