import { useEffect } from 'react';

import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { load as loadBotD } from '@fingerprintjs/botd';
import useCookie from 'react-use-cookie';
import { logError } from 'helpers/logError';
import useCurrentUser from './useCurrentUser';
import { FINGERPRINT_COOKIE, isTesting } from 'config/constants';

export const useFingerprint = () => {
  const [idCookie, setIdCookie] = useCookie(FINGERPRINT_COOKIE, '');
  const { user } = useCurrentUser();
  const { isLoading, error, data, getData } = useVisitorData(
    { linkedId: user.id },
    { immediate: false }
  );

  useEffect(() => {
    const fetchAndSaveVisitorID = async () => {
      const { bot } = (await loadBotD()).detect();
      if (bot && !isTesting) {
        return;
      }

      await getData();
      if (error || !data?.visitorId) {
        logError(error || Error('Failed to save fingerprint data'), {
          metadata: { fingerprint: { data } }
        });
      } else {
        setIdCookie(data.visitorId, { SameSite: 'Lax', domain: 'protonradio.com' });
      }
    };

    if (!idCookie && !isLoading && !error) {
      fetchAndSaveVisitorID().catch(logError);
    }
  }, [idCookie, setIdCookie, isLoading, error, data, getData]);

  return idCookie;
};

export default useFingerprint;
