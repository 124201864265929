import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column } from 'react-virtualized';
import styled from 'styled-components';

import DownloadTrackLink from 'components/DownloadTrackLink';
import DownloadTrackMenuToggler from 'components/DownloadTrackMenuToggler';
import DropdownMenu from 'components/DropdownMenu';
import { onAudioTableDoubleClick } from 'components/Lists/helpers';
import { TombstoneText } from 'components/Tombstone';
import TrackPlayButton from 'components/TrackPlayButton';
import { AUDIO_FILE_TYPES } from 'config/constants';
import { LOSSLESS_DOWNLOADS } from 'config/features/featureFlags';
import { formatSecondsDuration } from 'helpers';
import useProtonPlayer from 'hooks/useProtonPlayer';
import { selectIsFeatureActive } from 'redux/selectors';
import { makeSelectUserDownloadableTracksByCategory } from 'redux/selectors/tracks';

import { canDownloadLossless } from '../../helpers/tracks';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  getTracklistOrderColumnProps,
  OriginalArtistCell,
  RemixArtistCell,
  StyledTrackTable
} from './Shared';
import TracksTableDropdown from './TracksTableDropdown';
import TracksTableTitle from './TracksTableTitle';

const HEADER_HEIGHT = 25;
const ROW_HEIGHT = 54;

/**
 * [TracksTableDesktop]
 *
 * @param {bool} [showCompile] - if true, will show lock icon next to tracks with no_compile flag
 * @param {bool} [showOrder] - if true, will show tracklist_order column at the 1st column of the table
 * @param {bool} [showOrderPlayButton] - if true, will hide the cover art play button and show a round play button on
 * hover in place of tracklist order
 * @param {object[]} tracks - tracks to render in order
 * @param {object} tableProps - props needed for react virtualized
 * @param {bool} [fixedHeight] - handles calculating and passing height for fixed table (react virtualized)
 * @param {string} [queueName] - if passed, will show be set in player.queueName on play
 */

const TracksTableDesktop = ({
  showCompile,
  showOrder,
  showOrderPlayButton,
  tracks,
  tableProps,
  queueName,
  fixedHeight,
  downloadableTracks,
  userSettings,
  // redux connect:
  user,
  columns = {}
}) => {
  const [dropdownIndex, setDropdownIndex] = useState(null);

  const { user: currentUser } = useCurrentUser();
  const player = useProtonPlayer();

  const isLosslessDownloadsActive = useSelector(state =>
    selectIsFeatureActive(state, LOSSLESS_DOWNLOADS)
  );

  // If download icon should be displayed for any track, render the download column
  const displayDownloadColumn = !_.isEmpty(downloadableTracks.all);

  // If table is fixed height (no rendering rows out of the window on scroll), we need to calculate the table height
  const fixedTableProps = fixedHeight
    ? {
        height: ROW_HEIGHT * tracks.length + HEADER_HEIGHT
      }
    : {};

  return (
    <StyledTrackTable
      {...tableProps}
      {...fixedTableProps}
      showOrderPlayButton={showOrderPlayButton}
      headerHeight={HEADER_HEIGHT}
      rowHeight={ROW_HEIGHT}
      rowGetter={({ index }) => tracks[index]}
      // keep row highlighted when displaying dropdown
      rowClassName={({ index }) =>
        dropdownIndex === index
          ? 'Table__row TrackTableRow_js Table__row--active'
          : 'Table__row TrackTableRow_js'
      }
      onRowDoubleClick={args => {
        const { rowData: track } = args;
        return onAudioTableDoubleClick({
          clickArgs: args,
          queueAudio: tracks,
          queueIndex: track.__queueIndex,
          queueName,
          ignoreSelectors: [
            DropdownMenu.SELECTORS.TOGGLE,
            TrackPlayButton.SELECTOR,
            DownloadTrackMenuToggler.ToggleButton.TOGGLE_SELECTOR
          ]
        });
      }}
    >
      {showOrder && (
        <Column
          {...getTracklistOrderColumnProps({
            showOrderPlayButton,
            queueAudio: tracks,
            queueName,
            playerId: player.id,
            playerAudioType: player.type
          })}
        />
      )}

      <Column
        width={300}
        minWidth={200}
        flexGrow={3}
        label="Title"
        dataKey="track_name"
        cellRenderer={({ rowData: track }) => (
          <TracksTableTitle
            tracks={tracks}
            track={track}
            showCompile={showCompile}
            showOrderPlayButton={showOrderPlayButton}
            key={track.id}
          />
        )}
      />

      <Column
        width={300}
        flexGrow={2}
        label="Original Artists"
        dataKey="artists"
        cellRenderer={({ cellData: artists }) => <OriginalArtistCell artists={artists} />}
      />

      <Column
        width={300}
        flexGrow={2}
        label="Remix Artists"
        dataKey="artists"
        cellRenderer={({ cellData: artists }) => <RemixArtistCell artists={artists} />}
      />

      {columns.label !== false && (
        <Column
          width={300}
          flexGrow={1}
          label="Music Label"
          dataKey="name"
          disableSort
          cellRenderer={({ rowData: { label } }) => (
            <Link
              to={`${label.slug}/tracks`}
              title={label.name}
              data-testid="track-label"
            >
              {label.name}
            </Link>
          )}
        />
      )}

      {columns.release_date !== false && (
        <Column
          width={120}
          minWidth={90}
          label="Release Date"
          dataKey="date"
          disableSort
          cellRenderer={({ rowData: track }) => (
            <span data-testid="track-date">{track.release.date}</span>
          )}
        />
      )}

      <Column
        width={70}
        minWidth={55}
        label="Length"
        dataKey="duration_seconds"
        cellRenderer={({ cellData: duration }) => (
          <span data-testid="track-length">{formatSecondsDuration(duration)}</span>
        )}
      />

      {isLosslessDownloadsActive &&
        (currentUser.roles.admin || currentUser.roles.curator) &&
        displayDownloadColumn && (
          <Column
            width={32}
            minWidth={32}
            label=""
            dataKey="date"
            disableSort
            cellRenderer={({ rowData: track, rowIndex }) => {
              const canDownload = downloadableTracks.all[track.id];
              const promoTrack = downloadableTracks.promo[track.id];

              if (!canDownload) return null;

              return (
                <DownloadTrackLink
                  track={track}
                  format={AUDIO_FILE_TYPES.MP3.VALUE}
                  userSettings={userSettings}
                  showPromoWidget={!!promoTrack}
                  onOpen={() => setDropdownIndex(rowIndex)}
                  onClose={() => setDropdownIndex(null)}
                >
                  {({ open, isOpen }) => (
                    <DownloadTrackLink.ToggleButton onClick={open} active={isOpen} />
                  )}
                </DownloadTrackLink>
              );
            }}
          />
        )}

      {isLosslessDownloadsActive &&
        (currentUser.roles.admin || currentUser.roles.curator) &&
        displayDownloadColumn && (
          <Column
            width={32}
            minWidth={32}
            label=""
            dataKey="date"
            disableSort
            cellRenderer={({ rowData: track, rowIndex }) => {
              const canDownload =
                downloadableTracks.all[track.id] &&
                canDownloadLossless(currentUser, { user, track });

              if (!canDownload) return null;

              const promoTrack = downloadableTracks.promo[track.id];
              const preferredLosslessFormat = currentUser.settings['DOWNLOAD_FORMAT'];
              const downloadFormat =
                preferredLosslessFormat.value === 'mp3'
                  ? 'aiff'
                  : preferredLosslessFormat.value;

              return (
                <DownloadTrackLink
                  track={track}
                  format={downloadFormat}
                  userSettings={userSettings}
                  showPromoWidget={!!promoTrack}
                  onOpen={() => setDropdownIndex(rowIndex)}
                  onClose={() => setDropdownIndex(null)}
                >
                  {({ open, isOpen }) => (
                    <DownloadTrackLink.ToggleButton onClick={open} active={isOpen} />
                  )}
                </DownloadTrackLink>
              );
            }}
          />
        )}

      {!(currentUser.roles.admin || currentUser.roles.curator) &&
        displayDownloadColumn && (
          <Column
            width={40}
            minWidth={40}
            label=""
            dataKey="date"
            disableSort
            cellRenderer={({ rowData: track, rowIndex }) => {
              const canDownload = downloadableTracks.all[track.id];
              const promoTrack = downloadableTracks.promo[track.id];

              if (!canDownload) return null;

              return (
                <DownloadTrackMenuToggler
                  track={track}
                  showPromoWidget={!!promoTrack}
                  onOpen={() => setDropdownIndex(rowIndex)}
                  onClose={() => setDropdownIndex(null)}
                >
                  {({ open, isOpen }) => (
                    <DownloadTrackMenuToggler.ToggleButton
                      onClick={open}
                      active={isOpen}
                    />
                  )}
                </DownloadTrackMenuToggler>
              );
            }}
          />
        )}

      <Column
        width={45}
        minWidth={38}
        label=""
        dataKey="ellipsis"
        cellRenderer={({ rowData: track, rowIndex }) => (
          <TracksTableDropdown
            track={track}
            rowIndex={rowIndex}
            setDropdownIndex={setDropdownIndex}
            user={user}
            key={track.id}
          />
        )}
      />
    </StyledTrackTable>
  );
};

const StyledTombstoneRow = styled.div.attrs(() => ({
  className: 'ReactVirtualized__Table__row'
}))`
  height: ${ROW_HEIGHT}px;
  position: relative; // for border to show

  .Table__row__meta {
    width: 12rem;
  }
  :nth-child(even) {
    .Table__row__meta {
      width: 8rem;
    }
  }
`;

// Tombstone for one table row
TracksTableDesktop.Tombstone = () => (
  <StyledTombstoneRow>
    <div className="ReactVirtualized__Table__rowColumn">
      <TrackPlayButton.Tombstone />
    </div>

    <div className="Table__row__meta ReactVirtualized__Table__rowColumn">
      <TombstoneText
        size={TombstoneText.SIZES.MEDIUM}
        color={TombstoneText.COLORS.LIGHT}
      />
    </div>
  </StyledTombstoneRow>
);

TracksTableDesktop.SELECTORS = {
  ROW: '.ReactVirtualized__Table__row.TrackTableRow_js'
};

TracksTableDesktop.propTypes = {
  showCompile: PropTypes.bool,
  showOrder: PropTypes.bool,
  showOrderPlayButton: PropTypes.bool,
  tableProps: PropTypes.shape(),
  tracks: PropTypes.arrayOf(PropTypes.shape()),
  queueName: PropTypes.string,
  fixedHeight: PropTypes.bool,
  // Redux connect:
  user: PropTypes.shape(),
  downloadableTracks: PropTypes.shape({
    all: PropTypes.shape(),
    promo: PropTypes.shape()
  })
};

const makeMapStateToProps = () => {
  const selectUserDownloadableTracksByCategory =
    makeSelectUserDownloadableTracksByCategory();

  return (state, { tracks }) => ({
    user: state.user,
    downloadableTracks: selectUserDownloadableTracksByCategory(state, { tracks })
  });
};

export default connect(makeMapStateToProps, null)(TracksTableDesktop);
