import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { COLORS } from 'config/constants';

import Icon from 'components/Icon';
import Button from '../Button';

/**
 * [EllipsisButton] button with "•••" used to toggle dropdowns and action menus (see EllipsisButton.Mobile)
 *
 * @param {bool} [dark] - pass true if using over a dark background
 */

const EllipsisButton = React.forwardRef(
  ({ className, onClick, children, dark, isActive, ...rest }, ref) => {
    const _class = classNames('EllipsisButton', className, {
      'EllipsisButton--dark': dark,
      'EllipsisButton--display-only': !onClick,
      'EllipsisButton--isActive': isActive
    });

    return (
      <Button
        onClick={onClick}
        className={_class}
        color={Button.COLORS.TRANSPARENT}
        size={Button.SIZES.MINIMAL}
        innerRef={ref}
        flex
        {...rest}
      >
        {children}
        <Icon
          type={Icon.TYPES.MORE}
          width={12}
          color={dark ? COLORS.GRAY_MEDIUM : COLORS.GRAY_DARK}
        />
      </Button>
    );
  }
);

EllipsisButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  dark: PropTypes.bool,
  isActive: PropTypes.bool
};

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 4rem;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

/**
 * [EllipsisButton.Mobile] - similar to EllipsisButton, but with higher "slop" for mobile
 */

EllipsisButton.Mobile = ({ onClick, dark, className, ...rest }) => (
  <StyledButton
    onClick={onClick}
    className={className}
    color={Button.COLORS.TRANSPARENT}
    flex
    {...rest}
  >
    <Icon
      type={Icon.TYPES.MORE}
      width={12}
      color={dark ? COLORS.GRAY_MEDIUM : COLORS.GRAY_DARK}
    />
  </StyledButton>
);

EllipsisButton.Mobile.propTypes = EllipsisButton.propTypes;

export default EllipsisButton;
