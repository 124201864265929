import React, { ReactNode, useCallback, useEffect } from 'react';
import {
  AnyVariables,
  CombinedError,
  OperationResult,
  TypedDocumentNode,
  UseMutationState,
  useMutation
} from 'urql';

import { isProduction, isTesting } from 'config/constants';
import { findInGraphQLError } from 'graphql/helpers/utils';
import { GqlApiError, GqlApiResponse } from 'graphql/types';
import { logError } from 'helpers/logError';
import { showAlert } from 'redux/actions/ui';
import { ReplaceTypeOfKey } from 'types';
import { useAppDispatch } from './redux';
import useCurrentUser, { User } from './useCurrentUser';

type MutationResult<T, V extends AnyVariables> = UseMutationState<T, V> & {
  dataErrors: GqlApiError[] | null;
  hasErrors: boolean;
};
export type RunMutation<T, V extends AnyVariables> = (
  args: V
) => Promise<
  OperationResult<T, V> & { dataErrors: GqlApiError[] | null; hasErrors: boolean }
>;

type Data =
  | {
      [gqlQuery: string]: GqlApiResponse | 'Mutation';
    }
  | null
  | undefined;
type SomeError = { message: string };
export type CombinedErrorDisplay = ReplaceTypeOfKey<CombinedError, 'message', ReactNode>;

const isSkipped = (err: GqlApiError, skip?: { [key: string]: string }): boolean => {
  if (!err.path) return false;
  for (const key of err.path) {
    if (skip && skip[key] === err.message) return true;
  }
  return false;
};

const isProUser = (user: User) => user && (user.roles.labelManager || user.roles.artist);

const joinErrors = (data: Data): GqlApiError[] | null => {
  if (!data) return null;
  const joinedErrors = Object.keys(data)
    .map(key => key !== '__typename' && (data[key] as GqlApiResponse)?.errorDetails)
    .filter(Boolean)
    .reduce((a, b) => a.concat(b), []);
  return joinedErrors && joinedErrors.length ? joinedErrors : null;
};

const showIntercomChatWithErrors = (errors: SomeError[]) => {
  window.Intercom(
    'showNewMessage',
    `Hey Proton team! I'm getting an alert that an unexpected error occurred while trying to update info. ` +
      `For reference the error has the following message(s):\n\n` +
      `${errors.map(e => e.message).join('\n')}\n\n` +
      `Can you help look into this for me? Thanks so much. 🙏`
  );
};

const unexpectedErrorMessage = (isPro: boolean, errors: SomeError[]): ReactNode => (
  <>
    {'Sorry, an unexpected error occurred! Try again in a minute or '}
    {isPro ? (
      <span
        role="button"
        tabIndex={0}
        style={{ fontWeight: 'bold' }}
        onClick={() => showIntercomChatWithErrors(errors)}
      >
        contact support
      </span>
    ) : (
      'contact support@protonradio.com for help'
    )}
    .
  </>
);

const errorProperties = <T extends Data = Data, V extends AnyVariables = AnyVariables>(
  result: UseMutationState<T, V> | OperationResult<T, V>
): { dataErrors: GqlApiError[] | null; hasErrors: boolean } => {
  const dataErrors = joinErrors(result.data);
  const hasErrors = !!(result.error || dataErrors);
  return { dataErrors, hasErrors };
};

/**
 * Wraps the URQL `useMutation` hook to handle errors by dumping them into a
 * built-in alert. On production, the actual error message is squashed and
 * replaced by a generic user-friendly message.
 *
 * Accepts a `skip` object as an optional second parameter with key-value pairs indicating
 * specific error case(s) handled by the calling code, matching errors will not produce alerts.
 * For any key-value pair passed in this way, the key can match any member of the error's `path`
 * array, and the value is matched against the error's `message`.
 *
 * @remarks
 * Both "top-level" errors (see {@link https://formidable.com/open-source/urql/docs/basics/errors/ urql CombinedError})
 * and errors nested within the response data (arrays of {@link GqlApiError}) produce alerts but neither
 * will reject the Promise, since errors may co-exist with valid data.
 *
 * If an API error should result in a Promise rejection it is up to the caller to reject. For convenience
 * when checking for any API errors, urql's UseMutationState and OperationResult types are extended with
 * a `dataErrors` property which joins any nested error in the returned GraphQL result, and a `hasErrors`
 * boolean which is true on either kind of error. The two types of errors can be separately destructured
 * from the result if needed, as `const { error, dataErrors } = runSomeMutation();`
 *
 * Or for a basic check `const { hasErrors } = runSomeMutation();` also works.
 */
const useMutationWithAlert = <
  T extends Data = Data,
  V extends AnyVariables = AnyVariables
>(
  mutation: string | TypedDocumentNode<T, V>,
  skip?: { [key: string]: string }
): [MutationResult<T, V>, RunMutation<T, V>] => {
  const [mutationState, action] = useMutation<T, V>(mutation);
  const dispatch = useAppDispatch();
  const { user: currentUser } = useCurrentUser();

  useEffect(() => {
    const { error, data } = mutationState;
    if (error) {
      logError(error);

      const mfaRequiredError = findInGraphQLError(error, {
        extensionCode: 'MFA_REQUIRED'
      });

      // We show a verification modal if the user needs to verify their identity (see urqlConfig.ts)
      if (!mfaRequiredError) {
        const displayError: CombinedErrorDisplay = error;
        if (!isProduction || isTesting) {
          displayError.message = unexpectedErrorMessage(isProUser(currentUser), [
            { message: error.message }
          ]);
        }
        dispatch(showAlert(displayError));
      }
    }
    const errors = joinErrors(data);
    if (errors) {
      const skipAll = !skip ? false : errors && errors.every(e => isSkipped(e, skip));
      if ((isProduction || isTesting) && !skipAll) {
        dispatch(
          showAlert({
            message: unexpectedErrorMessage(isProUser(currentUser), errors),
            error: 'API error'
          })
        );
      } else {
        if (!skipAll) logError(errors, skip ? { metadata: { ignore: skip } } : undefined);
        for (const e of errors) {
          if (!isSkipped(e, skip)) dispatch(showAlert(e));
        }
      }
    }
  }, [currentUser, dispatch, mutationState, skip]);

  const runMutation: RunMutation<T, V> = useCallback(
    async (args: V) => {
      const result = await action(args);
      return { ...result, ...errorProperties(result) };
    },
    [action]
  );

  return [{ ...mutationState, ...errorProperties(mutationState) }, runMutation];
};

export default useMutationWithAlert;
