import { MY_FEED } from 'config/features/featureFlags';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getFollowedEntities } from 'redux/actions/followed';
import { selectIsFeatureActive } from 'redux/selectors';
import { selectUser } from 'redux/selectors/user';

/**
 * [useFetchFollowedEntities] - Fetch the different entities the current user follows.  Used in MyFeed.
 */

const useFetchFollowedEntities = () => {
  const dispatch = useDispatch();
  const { user_id: userId } = useSelector(selectUser);
  const isActive = useSelector(state => selectIsFeatureActive(state, MY_FEED));

  useEffect(() => {
    if (!userId || !isActive) return;
    dispatch(getFollowedEntities());
  }, [dispatch, userId, isActive]);
};

export default useFetchFollowedEntities;
