import React from 'react';
import styled from 'styled-components';

import Container from 'components/Layout/Container';
import Grid from 'components/Layout/Grid';
import Page from 'components/Layout/Page';
import TabbedHeader, {
  TabbedHeaderProps,
  TabbedHeaderTombstone
} from 'components/TabbedHeader/TabbedHeader';
import { BREAKPOINTS } from 'config/constants';
import useBreakpoint from 'hooks/useBreakpoint';

const Main = styled.div`
  // In order to keep width within parent container, we apply min-width: 0
  // https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
  min-width: 0;
`;

const SidebarContainer = styled.div`
  margin-bottom: 6rem;
  position: relative;
  width: 100%;
`;

type ColumnStyle = {
  default?: string;
  lg?: string;
  md?: string;
};

type Props = {
  renderSidebar?: ({ hasNotification }: { hasNotification: boolean }) => React.ReactNode;
  renderMain: () => React.ReactNode;
  header: TabbedHeaderProps;
  columns?: ColumnStyle;
  container?: boolean;
  sidebarBreakpoint?: number;
};

const LG_SIDEBAR_WIDTH = '25rem';
const MD_SIDEBAR_WIDTH = '23.5rem';
const TabbedHeaderColumnLayout = {
  // sidebar layout at lg breakpoint
  [BREAKPOINTS.LG]: { default: `${LG_SIDEBAR_WIDTH} 1fr`, lg: '1fr' },
  // sidebar layout at md breakpoint
  [BREAKPOINTS.MD]: {
    default: `${LG_SIDEBAR_WIDTH} 1fr`,
    lg: `${MD_SIDEBAR_WIDTH} 1fr`,
    md: '1fr'
  }
};

const TabbedHeaderLayout = ({
  renderSidebar,
  renderMain,
  header,
  columns,
  container = true,
  sidebarBreakpoint = BREAKPOINTS.LG
}: Props) => {
  const isSidebarBreakpoint = useBreakpoint(sidebarBreakpoint, 'up');
  const isLargeScreen = useBreakpoint(BREAKPOINTS.LG, 'up');
  const displaySidebar = isSidebarBreakpoint && renderSidebar;
  const defaultColumns = columns || TabbedHeaderColumnLayout[sidebarBreakpoint];

  const hasHeaderNotification = header.renderNotification && header.renderNotification();

  // Set defaults for header props if not already set
  const { loading, seo, ...headerPropsRest } = header;
  const headerProps = {
    ...headerPropsRest,
    imageWidth: header.imageWidth || '25rem',
    seo: {
      title: seo?.title || (typeof header.title === 'string' ? header.title : ''),
      description:
        seo?.description || (typeof header.subtitle === 'string' ? header.subtitle : ''),
      image: seo?.image || header.imageUrl,
      section: seo?.section || header.titleLabel
    }
  };

  const content = (
    <Grid
      columns={defaultColumns}
      gap={isLargeScreen ? '3rem' : '2.2rem'}
      data-debugid="TabbedHeaderLayout-Grid"
    >
      {displaySidebar && (
        <SidebarContainer>
          {renderSidebar({
            hasNotification: !!hasHeaderNotification
          })}
        </SidebarContainer>
      )}
      <Main>{renderMain()}</Main>
    </Grid>
  );

  return (
    <Page.Wrapper container={false} style={{ paddingTop: '0' }}>
      <>
        {loading ? (
          <TabbedHeaderTombstone {...headerProps} />
        ) : (
          <TabbedHeader {...headerProps} />
        )}
      </>

      {container ? (
        <Container size={Container.SIZES.EXTRA_LARGE}>{content}</Container>
      ) : (
        content
      )}
    </Page.Wrapper>
  );
};

export default TabbedHeaderLayout;
