import React from 'react';
import styled from 'styled-components';

import { COLORS } from 'config/constants/styles';

const TYPES = {
  PRIMARY: 'primary',
  DANGER: 'danger',
  SECONDARY: 'secondary',
  DARK: 'dark',
  TRANSPARENT: 'transparent'
};

const StyledBadge = styled.div<{
  $badgeType?: string;
  $fit?: boolean;
  $noMargin?: boolean;
}>`
  background: ${({ $badgeType }) => {
    switch ($badgeType) {
      case TYPES.PRIMARY:
        return COLORS.PRIMARY;
      case TYPES.DANGER:
        return COLORS.DANGER;
      case TYPES.DARK:
        return 'rgba(255, 255, 255, 0.15)';
      case TYPES.TRANSPARENT:
        return 'transparent';
      default:
        return COLORS.SECONDARY;
    }
  }};
  color: ${({ $badgeType }) => {
    if ($badgeType === TYPES.SECONDARY || $badgeType === TYPES.TRANSPARENT) {
      return '#666666';
    }

    return COLORS.WHITE;
  }};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : undefined)};
  border-radius: 10rem;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
  height: ${({ $fit }) => ($fit ? 'auto' : '1.6rem')};
  letter-spacing: 0.2em;
  line-height: 1.8rem;
  margin-top: ${({ $noMargin }) => ($noMargin ? 0 : '0.8rem')};
  padding: 0rem 1rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
`;

interface BadgeProps {
  type?: string;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  noMargin?: boolean; // removes default margin on badge
  fit?: boolean; // removes default height on badge so that it will auto-fit content
  testId?: string;
  style?: React.CSSProperties;
}

const Badge = ({
  type = TYPES.SECONDARY,
  onClick,
  children,
  className,
  noMargin,
  fit,
  testId,
  style
}: BadgeProps) => (
  <StyledBadge
    onClick={onClick}
    className={className}
    style={style}
    data-testid={testId || 'Badge'}
    $badgeType={type}
    $noMargin={noMargin}
    $fit={fit}
  >
    {children}
  </StyledBadge>
);

Badge.TYPES = TYPES;

export default Badge;
