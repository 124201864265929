import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { List } from 'react-virtualized';

import { TrackActionMenu } from 'components/ActionMenu/ActionMenus';
import useProtonPlayer from 'hooks/useProtonPlayer';
import useToggle from 'hooks/useToggle';
import { makeSelectUserDownloadableTracksByCategory } from 'redux/selectors/tracks';

import TrackMobileTile from './TrackMobileTile';

const TracksTableMobile = props => {
  const {
    tracks,
    tableProps,
    showCompile,
    // redux connect state:
    downloadableTracks
  } = props;
  const player = useProtonPlayer();
  const playing = player.currentTrack;

  const { isOpen, open: openMenu, close: closeMenu } = useToggle();
  const [menuTrack, setMenuTrack] = useState(null);

  return (
    <>
      <List
        {...tableProps}
        // overscanRowCount: 15  // 10 is default
        rowHeight={80}
        rowRenderer={({ index, style, key }) => {
          const track = tracks[index];

          return (
            <div key={key} style={style}>
              <TrackMobileTile
                track={track}
                showCompile={showCompile}
                onClick={() => {
                  player.send('playAudio', {
                    queue: tracks,
                    index
                  });
                }}
                onToggle={() => {
                  setMenuTrack(track);
                  openMenu();
                }}
                isDownloadable={!!downloadableTracks.all[track.id]}
              />
            </div>
          );
        }}
      />

      {menuTrack && (
        <TrackActionMenu
          // this will unmount on navigation change
          closeOnNavigation={false}
          track={menuTrack}
          isOpen={isOpen}
          close={closeMenu}
          showCompile={showCompile}
        />
      )}
    </>
  );
};

TracksTableMobile.Tombstone = TrackMobileTile.Tombstone;

TracksTableMobile.propTypes = {
  listProps: PropTypes.shape(),
  tracks: PropTypes.arrayOf(PropTypes.shape()),
  tableProps: PropTypes.shape(),
  showCompile: PropTypes.bool,
  queueName: PropTypes.string,
  // redux connect
  downloadableTracks: PropTypes.shape()
};

export default connect(() => {
  const selectUserDownloadableTracksByCategory =
    makeSelectUserDownloadableTracksByCategory();

  // flatten player props so react's shallow comparison doesn't cause rerenders when player updates
  return (state, { tracks }) => ({
    downloadableTracks: selectUserDownloadableTracksByCategory(state, { tracks })
  });
})(TracksTableMobile);
