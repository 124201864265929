import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { generateSelectorsFromTestIds, getOrderedTrackArtists } from 'helpers';
import { COLORS } from 'config/constants/styles';

import TextHeader from 'components/TextHeader';
import { EllipsisButton } from 'components/Button';
import PlayerButton from 'components/PlayerButton';
import PromoReactionWidget from 'components/PromoReactionWidget';
import { TombstoneText } from 'components/Tombstone';
import Highlight from 'components/Highlight';
import CommaList from 'components/CommaList';
import Icon from 'components/Icon';

import { selectPromoTrackReaction } from 'redux/selectors/promoReleases';

const TRACK_ORDER_WIDTH = '3rem';

const StyledContainer = styled.div`
  position: relative;

  &:after {
    background-color: ${COLORS.BORDER};
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: ${TRACK_ORDER_WIDTH};
    right: 0;
    position: absolute;
    /* hairline width hack */
    background: linear-gradient(
      transparent 0%,
      transparent 50%,
      #f0f0f0 50%,
      #f0f0f0 100%
    );
  }
`;

const TileRow = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

const TrackOrder = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  font-size: 1.6rem;
  font-weight: 500;
  justify-content: center;
  /* offset number to towards the left */
  padding-right: 1rem;
  width: ${TRACK_ORDER_WIDTH};
`;

const TrackMeta = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0.6rem 0;

  div,
  .TextHeader {
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ReactionWidgetWrapper = styled.div`
  margin: 1rem 0;
`;

/**
 * [TracklistTableRowMobile]
 * Displays track as part of tracklist. Handles
 *
 * Made for use in TracklistTableMobile
 *
 * @param {object} track - track to be displayed
 * @param {bool} [active] - if track is set in the player.
 * @param {bool} [isPromoTrack]
 * @param {bool} [inlinePromoWidget] - if true, enable the promo widget to be displayed beneath the playing (active) track
 * - used in Inbox view
 * @param {func} onRowClick
 * @param {bool} [canDownloead] - if true, download icon will be displayed
 * @param {func} onActionClick
 */

const TracklistTableRowMobile = ({
  track,
  onRowClick,
  onActionClick,
  active,
  isPromoTrack,
  canDownload,
  inlinePromoWidget
}) => {
  const orderedArtists = getOrderedTrackArtists(track.artists);
  const reaction = useSelector(state => selectPromoTrackReaction(state, track.id));

  return (
    <StyledContainer data-testid={TracklistTableRowMobile.TEST_IDS.CONTAINER}>
      <TileRow>
        <Content role="button" onClick={() => onRowClick(track)}>
          <TrackOrder>
            {active ? (
              <PlayerButton audio={track} classes="PlayerButton--mini" displayOnly />
            ) : (
              track.tracklist_order
            )}
          </TrackOrder>

          <TrackMeta>
            <TextHeader type={TextHeader.TYPES.MEDIUM} weight={TextHeader.WEIGHTS.NORMAL}>
              <Highlight hit={track} attribute="title" />
              {track.version ? (
                <>
                  {' '}
                  (<Highlight hit={track} attribute="version" />)
                </>
              ) : null}
            </TextHeader>
            <div>
              <CommaList
                data={orderedArtists}
                attribute="name"
                highlightAttribute="artists.$.name"
                hit={track}
              />
            </div>
          </TrackMeta>
        </Content>

        <EllipsisButton
          onClick={() => onActionClick(track)}
          data-testid={TracklistTableRowMobile.TEST_IDS.MENU_TOGGLE}
        >
          {canDownload && (
            <Icon
              type={Icon.TYPES.DOWNLOAD}
              width={16}
              color={reaction?.downloaded_at ? Icon.COLORS.PRIMARY : Icon.COLORS.LIGHT}
              className="mr-3"
              data-testid="track-download-icon"
            />
          )}
        </EllipsisButton>
      </TileRow>

      {active && isPromoTrack && inlinePromoWidget && (
        <ReactionWidgetWrapper>
          <PromoReactionWidget track={track} />
        </ReactionWidgetWrapper>
      )}
    </StyledContainer>
  );
};

TracklistTableRowMobile.Tombstone = ({ index = 1 }) => {
  const widthModifier = (index + 1) % 2 === 0 ? 1.1 : 1;

  return (
    <StyledContainer>
      <TileRow>
        <Content>
          <TrackOrder>
            <TombstoneText
              size={TombstoneText.SIZES.MEDIUM}
              color={TombstoneText.COLORS.LIGHT}
              style={{ width: '1.2rem' }}
            />
          </TrackOrder>

          <TrackMeta>
            <TombstoneText
              size={TombstoneText.SIZES.MEDIUM}
              color={TombstoneText.COLORS.LIGHT}
              style={{ width: `${widthModifier * 12}rem` }}
            />
            <TombstoneText
              size={TombstoneText.SIZES.XSMALL}
              color={TombstoneText.COLORS.LIGHT}
              style={{ width: `${widthModifier * 7}rem`, marginTop: '0.3rem' }}
            />
          </TrackMeta>
        </Content>
      </TileRow>
    </StyledContainer>
  );
};

TracklistTableRowMobile.TEST_IDS = {
  CONTAINER: 'TracklistTableRowMobile',
  MENU_TOGGLE: 'ReleaseTrack-menu-toggle'
};

TracklistTableRowMobile.SELECTORS = generateSelectorsFromTestIds(
  TracklistTableRowMobile.TEST_IDS
);

TracklistTableRowMobile.propTypes = {
  track: PropTypes.shape(),
  onRowClick: PropTypes.func,
  onActionClick: PropTypes.func,
  active: PropTypes.bool,
  isPromoTrack: PropTypes.bool,
  canDownload: PropTypes.bool,
  inlinePromoWidget: PropTypes.bool
};

export default TracklistTableRowMobile;
