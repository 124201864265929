import { DEFAULT_ARTIST_IMAGES } from 'config/constants';

/**
 * [getDefaultArtistImage] - repeatably assign default image url based on character code
 * @param {string} name - uses name of artist to create an integer which is used to select the default image url
 * @returns {string} default image url
 */

export const getDefaultArtistImage = name => {
  const codeSum = name.split('').reduce((accum, character) => {
    const charCode = character.charCodeAt(0);
    const code = Number.isNaN(charCode) ? 0 : charCode;
    return accum + code;
  }, 0);

  return DEFAULT_ARTIST_IMAGES[codeSum % DEFAULT_ARTIST_IMAGES.length];
};

const EMPTY_DATA = {};

/**
 * [reformatTransactionToArtistProfile] - takes transaction from artist profile
 * and returns an object that is represntative of the artist profile object
 *
 * @param {object} [transaction] - wizard transaction to reformat as artist profile
 * @param {object} [artistData] additional datta to merge with transaction data. Transaction data
 * will overwrite this data in the returned object
 * @returns {object}
 */

export const reformatTransactionToArtistProfile = (transaction, artistData = {}) => {
  const { data = EMPTY_DATA } = transaction;
  const transactionArtist = data.artist;

  return {
    members: [],
    connections: [],
    ...artistData,
    ...transactionArtist,
    __transactionId: transaction.id
  };
};

/**
 * [isArtistSoundCloudBypassEnabled] - checks the artist profile for the artist_config that allows artist
 * soundcloud verification step to be skipped in various artist profile wizard contexts.
 *
 * @param {object} artistProfile
 * @returns {boolean}
 */

export const isArtistSoundCloudBypassEnabled = artistProfile =>
  artistProfile?.artist_configs?.find(
    ({ config_key, config_value }) =>
      config_key === 'skip_soundcloud_verification' && config_value === true
  );

/**
 * [getArtistDspConnection] - checks the artist profile connections for a dsp connection matching the passed
 * service.
 *
 * @param {object} artistProfile
 * @param {string} service - See DIGITAL_SERVICE_PROVIDERS constant
 * @returns {object}
 */

export const getArtistDspConnection = ({ artistProfile, service }) =>
  artistProfile.connections.find(({ dsp }) => dsp === service);
