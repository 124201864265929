/** [calculateAverageColor()] – Calculates the average between two provided colors
 * @param {string} newColor – Hex code of new color you want to transition to
 * @param {string} oldColor - Hex code of the old color youre transitioning from
 * @param {number} ratio - A value from 0 to 1 that signifies how close you want to be from the old vs new color
 */

export const calculateAverageColor = (newColor, oldColor, ratio) => {
  const hex = x => {
    const value = x.toString(16);
    return value.length === 1 ? `0${value}` : value;
  };

  const r = Math.ceil(
    parseInt(oldColor.substring(0, 2), 16) * ratio +
      parseInt(newColor.substring(0, 2), 16) * (1 - ratio)
  );
  const g = Math.ceil(
    parseInt(oldColor.substring(2, 4), 16) * ratio +
      parseInt(newColor.substring(2, 4), 16) * (1 - ratio)
  );
  const b = Math.ceil(
    parseInt(oldColor.substring(4, 6), 16) * ratio +
      parseInt(newColor.substring(4, 6), 16) * (1 - ratio)
  );

  const average = hex(r) + hex(g) + hex(b);
  return average;
};
