import React from 'react';
import { Link } from 'react-router-dom';

import routeMap from 'config/routes';

import GitHubLogo from 'components/Icons/GitHubLogo';
import facebookIcon from 'images/icon-facebook.svg';
import twitterIcon from 'images/icon-twitter.svg';

const AppFooter = () => (
  <div className="Footer">
    <div className="Footer__left">
      <div className="Footer__left__top">
        <a href={routeMap.external.support} target="_blank" rel="noopener noreferrer">
          Feedback &amp; Support
        </a>
        <span> / </span>
        <a href={routeMap.external.hiring} target="_blank" rel="noopener noreferrer">
          Careers: We&apos;re hiring!
        </a>
      </div>
      <div className="Footer__left__bottom">
        <a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">
          Sitemap
        </a>
        <span> / </span>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <span> / </span>
        <span>&copy; {new Date().getFullYear()} Proton LLC</span>
        <span className="Footer__version">
          {process.env.REACT_APP_COMMIT_SHA1
            ? `v.(${process.env.REACT_APP_COMMIT_SHA1.slice(0, 6)})`
            : `Version N/A`}
        </span>
      </div>
    </div>
    <div className="Footer__right">
      <a
        href="https://www.facebook.com/protonradio/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={facebookIcon} alt="Facebook Icon" />
      </a>
      <a href="https://twitter.com/ProtonRadio" target="_blank" rel="noopener noreferrer">
        <img src={twitterIcon} alt="Twitter Icon" />
      </a>
      <a href="https://github.com/protonradio" target="_blank" rel="noopener noreferrer">
        <GitHubLogo size={20} color="#DCDBDB" />
      </a>
    </div>
  </div>
);

export default AppFooter;
