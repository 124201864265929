import React from 'react';

import { COLORS } from 'config/constants';
import { IconProps } from './iconTypes';

const BeatportLogo = ({
  color = COLORS.BEATPORT_GREEN,
  size = 18,
  className,
  style
}: IconProps) => (
  <svg height={size} viewBox="0 0 67.9 84.6" className={className} style={style}>
    <title>Beatport</title>
    <g id="Beatport-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-283, -288)">
        <g
          id="Beatport_2021"
          transform="translate(283, 288)"
          fill={color}
          fill-rule="nonzero"
        >
          <path
            d="M67.9,60 C67.9,73.6 57.1,84.6 43.3,84.6 C29.7,84.6 18.9,73.9 18.9,60 C18.9,53.5 21.4,47.7 25.3,43.4 L8.7,60 L0,51.2 L18.7,32.7 C21.2,30.2 22.5,26.9 22.5,23.2 L22.5,0 L34.8,0 L34.8,23.3 C34.8,30.4 32.3,36.5 27.3,41.4 L26.8,41.9 C31.1,37.9 37,35.5 43.3,35.5 C57.3,35.5 67.9,46.6 67.9,60 M56.8,60 C56.8,52.7 50.8,46.8 43.4,46.8 C35.9,46.8 30,53 30,60 C30,67.2 35.9,73.3 43.3,73.3 C51,73.3 56.8,67 56.8,60"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default BeatportLogo;
