import PropTypes from 'prop-types';
import React from 'react';

import { getPromoReleaseActions } from 'helpers/promos';

import ActionMenu from 'components/ActionMenu/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';

/**
 * [PromoReleaseActionMenu] - Action menu for releases in user's Promo Inbox
 * @param {object} label
 * @param {object} release
 *
 * Action menu props:
 * @param {bool} isOpen open state - likely from useToggle in parent component
 * @param {function} close toggle function for open state - likely from useToggle in parent component
 * @param {function} closeOnNavigation
 */

const PromoReleaseActionMenu = ({
  label,
  release,
  // action menu props:
  isOpen,
  close,
  closeOnNavigation
}) => {
  const actions = getPromoReleaseActions(release, label);

  return (
    <>
      <ActionMenu isOpen={isOpen} close={close} closeOnNavigation={closeOnNavigation}>
        <ActionMenuList actions={actions} closeMenu={close} />
      </ActionMenu>
    </>
  );
};

PromoReleaseActionMenu.propTypes = {
  label: PropTypes.shape(),
  release: PropTypes.shape(),
  // Action menu props:
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  closeOnNavigation: PropTypes.bool
};

export default PromoReleaseActionMenu;
