import { USER_PROFILE_TYPES } from 'config/constants';
/**
 * Returns an object of user's profiles for given type keyed by id
 *
 * @param {import('types').V2ProUser} user
 * @param {'label' | 'artist'} profileType which profiles to return for user ('label', 'artist')
 *
 * @returns {{[id: number]: import('types').UserProfile | undefined}}
 *
 */
export const getUserProfilesById = (user, profileType) =>
  Object.fromEntries(
    user.profiles
      .filter(({ type }) => type === profileType)
      .map(profile => [profile.id, profile])
  );

export const isAdminUser = user => user.accesslevel === 0;

export const isProUser = user => user.account_type === 'pro';

/**
 * [isUserLabelManager] - check if current user manages a label
 *
 * @param {object} user
 * @returns {bool}
 */

export const isUserLabelManager = (user = {}) =>
  user.profiles?.some(profile => profile.type === USER_PROFILE_TYPES.LABEL);

/**
 * [getLabelsUserManages] returns array of labels the user manages
 *
 * @param {import('types').V2ProUser} user
 * @returns {import('types').UserLabelProfile[]}
 */
export const getLabelsUserManages = user =>
  user.profiles.filter(({ type }) => type === USER_PROFILE_TYPES.LABEL);

/**
 *
 * @param {import('types').V2ProUser} user
 * @returns {number[]}
 */
export const getLabelIdsUserManages = user =>
  getLabelsUserManages(user).map(({ id }) => id);

/**
 * [isUserLabelManagerForArtist] - determines if user is a label manager for provided artist profile
 * by checking if the artist has a release with ANY label that the user manages
 *
 * @param {object} user
 * @param {object} artistProfile
 * @returns {bool}
 */

export const isUserLabelManagerForArtist = (user, artistProfile) => {
  const userLabelIds = getLabelIdsUserManages(user);
  return artistProfile.releases_on?.some(({ id }) => userLabelIds.includes(id));
};

/**
 * [isUserLabelManagerForLabel] - determines if user is a label manager for provided label id
 *
 * @param {object} user
 * @param {number} labelId
 * @returns {bool}
 */

export const isUserLabelManagerForLabel = (user = {}, labelId) =>
  user.profiles?.some(
    profile => profile.type === USER_PROFILE_TYPES.LABEL && profile.id === labelId
  );

/**
 * [isUserArtistMember] - checks user is a member of ANY artist
 * @param {object} user
 * @returns {bool}
 */

export const isUserArtistMember = user =>
  user.profiles?.some(({ type }) => type === USER_PROFILE_TYPES.ARTIST);

/**
 * [isUserArtistMemberForArtist] - checks user object (redux) for a artist profile that matches passed artist
 * @param {object} user
 * @param {string} artistId
 * @returns {bool}
 */

export const isUserArtistMemberForArtist = (user, artistId) =>
  user.profiles?.some(
    ({ type, id }) => type === USER_PROFILE_TYPES.ARTIST && id === artistId
  );

/**
 * [getUserSetting] - Pluck first matching setting from user.user_settings array
 * @param {array|object} userData – Value passed in from user.user_settings or the full user object
 * @param {string} settingName - Value of user.user_settings[x].setting to be matches against. Use
 * USER_SETTINGS constant
 * @returns {object|undefined}
 */

export const getUserSetting = (userData, settingName) => {
  const allSettings = userData.user_settings || userData;

  return allSettings.find(({ setting }) => {
    if (!settingName) return null;
    return setting === settingName;
  });
};

/**
 * [isUserArtistMemberOnTrack] - checks to see if the user has an artist profile that is an artist on the passed track
 *
 * @param {object} track
 * @param {object} user
 * @returns {bool} true if user has an artist id that matches an artist id on the track
 */

export const isUserArtistMemberOnTrack = (user, track) =>
  track.artists.some(({ id }) => isUserArtistMemberForArtist(user, id));

/**
 * [isUserArtistMemberOnRelease] - checks to see if the user has an artist profile that is an artist on the
 * passed tracks
 *
 * @param {object} releaseTracks
 * @param {object} user
 * @returns {bool} true if user has an artist id that matches an artist id on a track in the release
 */

export const isUserArtistMemberOnRelease = (user, releaseTracks) =>
  releaseTracks.some(track => isUserArtistMemberOnTrack(user, track));

/**
 * [isSignedIn] - checks if the user is currently signed in
 * @param {object} user
 * @returns {bool}
 */
export const isSignedIn = user => user.user_id != null;
