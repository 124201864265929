import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';

import { COLORS, ENTITY_TYPES } from 'config/constants';
import { getReleaseHeadlineArtists } from 'helpers/release';

import TextHeader from 'components/TextHeader';
import { TombstoneText } from 'components/Tombstone';
import ReleaseHeadliner from 'components/ReleaseHeadliner';

import EntityImage from './EntityImage';
import { getEntityPath } from './helpers';
import { MemberDataSubtitle } from 'components/MemberDataSubtitle';

const CIRCLE_ENTITY_TYPES = [ENTITY_TYPES.ARTIST, ENTITY_TYPES.DSP_ARTIST_PROFILE];

export const EntityContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  ${({ $size }) =>
    $size === 'large' &&
    `
    align-items: flex-start;
    flex-direction: column;
  `};
  a {
    color: inherit;
    &:hover {
      color: ${COLORS.PRIMARY};
      text-decoration: none;
    }
  }
  ${({ $center }) => $center && `align-items: center;`}
`;

const EntityDetails = styled.div`
  margin-top: ${({ $size }) => $size === 'large' && '0.8rem'};
  min-width: ${({ $size }) => ($size === 'large' ? 'initial' : '12rem')};
  padding-left: ${({ $size }) => ($size === 'large' ? '0' : '1rem')};
  text-align: ${({ type, $size }) => {
    if ($size === 'large' && CIRCLE_ENTITY_TYPES.includes(type)) return 'center';
  }};
  width: 100%;
  flex: ${({ $size }) => $size === 'small' && '1'};
  // If entity image is not rendered, this will be the first child element & does not need left padding
  &:first-child {
    padding-left: 0;
  }
`;

const EntityTitle = styled(TextHeader)`
  margin-bottom: 0.4rem;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $center }) =>
    $center &&
    `
    display: flex;
    justify-content: center;
  `};
`;

const EntitySubtitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLink = styled(Link)``;

const getEntityDetails = (type, data) => {
  switch (type) {
    case ENTITY_TYPES.ARTIST:
      return {
        title: data.name,
        imageUrl: data.image_url
      };

    case ENTITY_TYPES.USER: {
      return {
        title: (
          <>
            {data.first_name} {data.last_name}
          </>
        ),
        titleLink: false, // NOTE: there is no user page to link to
        subtitle: <MemberDataSubtitle pro_email={data.pro_email} country={data.country} />
      };
    }

    case ENTITY_TYPES.RELEASE: {
      const headlinerArtists = getReleaseHeadlineArtists(data);
      const subtitle = <ReleaseHeadliner headlinerArtists={headlinerArtists} />;

      return {
        title: data.name,
        subtitle
      };
    }

    case ENTITY_TYPES.LABEL:
      return {
        title: data.name,
        imageUrl: data.image
      };

    case ENTITY_TYPES.TRACK:
      return {
        title: data.title
      };

    case ENTITY_TYPES.DSP_ARTIST_PROFILE:
      return {
        title: data.username
      };

    case ENTITY_TYPES.SHOW:
      return {
        title: data.name,
        imageUrl: data.show_image
      };

    default:
      return {
        title: '',
        subtitle: ''
      };
  }
};

const shouldCenterTitle = (type, size) =>
  CIRCLE_ENTITY_TYPES.includes(type) && size.name === EntityElement.SIZES.LARGE.name;

/**
 * [EntityElement] - default component for displaying entities for various sizes.  See storybook
 *
 * @param {string} [title] - optionally override default title content for entity type
 * @param {string} [subtitle] - optionally override default subtitle content for entity type
 * @returns {object}
 */

const EntityElement = ({
  type,
  data = {},
  title,
  subtitle,
  imageUrl,
  path,
  enableLinks = true,
  fluid = false,
  size = EntityElement.SIZES.LARGE,
  children,
  className,
  style,
  lazy = true
}) => {
  const {
    title: defaultTitle,
    titleLink = true,
    subtitle: defaultSubtitle
  } = getEntityDetails(type, data);
  const entityPath = path || getEntityPath(type, data);
  const lines = size === EntityElement.SIZES.LARGE ? 2 : 1;
  const center = shouldCenterTitle(type, size);

  return (
    <EntityContainer
      $size={size.name}
      $center={center}
      data-testid={EntityElement.TEST_IDS.CONTAINER}
      className={className}
      style={style}
    >
      <EntityImage
        data={data}
        imageUrl={imageUrl}
        path={entityPath}
        type={type}
        size={size}
        fluid={fluid}
        enableLinks={enableLinks}
        lazy={lazy}
      />

      <EntityDetails $size={size.name} $width={size.width} type={type}>
        <StyledLink
          to={entityPath}
          as={!enableLinks || !titleLink ? 'div' : undefined}
          data-testid={EntityElement.TEST_IDS.TITLE}
        >
          <EntityTitle type={size.headerSize} $center={center}>
            {title ? title : <Truncate lines={lines}>{defaultTitle}</Truncate>}
          </EntityTitle>
        </StyledLink>

        <EntitySubtitle data-testid={EntityElement.TEST_IDS.SUBTITLE}>
          {defaultSubtitle || subtitle}
        </EntitySubtitle>
      </EntityDetails>

      {children}
    </EntityContainer>
  );
};

EntityElement.TYPES = ENTITY_TYPES;

EntityElement.SIZES = {
  SMALL: {
    name: 'small',
    minWidth: 40,
    headerSize: TextHeader.TYPES.MEDIUM
  },
  MEDIUM: {
    name: 'medium',
    minWidth: 60,
    headerSize: TextHeader.TYPES.SMALL
  },
  LARGE: {
    name: 'large',
    minWidth: 200,
    headerSize: TextHeader.TYPES.MEDIUM
  }
};

EntityElement.TEST_IDS = {
  CONTAINER: 'EntityElement-container',
  TITLE: 'EntityElement-title',
  SUBTITLE: 'EntityElement-subtitle'
};

EntityElement.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.shape(),
  enableLinks: PropTypes.bool,
  fluid: PropTypes.bool,
  lazy: PropTypes.bool,
  size: PropTypes.shape(),
  subtitle: PropTypes.node,
  title: PropTypes.node,
  type: PropTypes.string,
  imageUrl: PropTypes.string,
  path: PropTypes.string,
  style: PropTypes.shape()
};

EntityElement.Tombstone = ({ size, className, style, fluid = false, type, index }) => {
  const isOdd = index % 2 === 0;
  const center = shouldCenterTitle(type, size);

  return (
    <EntityContainer
      $size={size.name}
      $width={size.width}
      $center={center}
      className={className}
      style={style}
    >
      <EntityImage type={type} size={size} loading fluid={fluid} />

      <EntityDetails $size={size.name} className="mt-2">
        <EntityTitle type={size.headerSize} $center={center}>
          <TombstoneText
            size={
              size === EntityElement.SIZES.LARGE
                ? TombstoneText.SIZES.LARGE
                : TombstoneText.SIZES.MEDIUM
            }
            style={{ width: isOdd ? '50%' : '65%' }}
          />
        </EntityTitle>

        {/* Only release has subtitle */}
        {type === ENTITY_TYPES.RELEASE && (
          <TombstoneText
            size={
              size === EntityElement.SIZES.LARGE
                ? TombstoneText.SIZES.MEDIUM
                : TombstoneText.SIZES.SMALL
            }
            style={{ width: isOdd ? '70%' : '100%' }}
          />
        )}
      </EntityDetails>
    </EntityContainer>
  );
};

EntityElement.Tombstone.propTypes = {
  fluid: PropTypes.bool,
  size: PropTypes.shape(),
  center: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.string,
  index: PropTypes.number
};

export default EntityElement;
