import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectIsFeatureActive } from 'redux/selectors';
import { selectIsTrackDownloadable } from 'redux/selectors/tracks';
import { selectIsTrackOnPromo } from 'redux/selectors/promoReleases';
import { selectUser } from 'redux/selectors/user';
import { useQuery } from 'urql';

import { ReleaseDspQuery } from 'graphql/queries/release';
import { releaseStoreInfoButtonsTransform } from 'graphql/transforms';
import { getTrackActions } from 'helpers';

import { TRACK_PAGE } from 'config/features/featureFlags';

import ActionMenu from 'components/ActionMenu/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import Container from 'components/Layout/Container';
import PromoReactionWidget from 'components/PromoReactionWidget';
import DownloadTrackActionItem from './DownloadTrackActionItem';
import TrackMenuHeader from './TrackMenuHeader';

/**
 * [TrackActionMenu] - Generic action menu used for TrackTables across app
 *
 * @param {object} track
 * @param {bool} showCompile
 *
 * Action menu props
 * @param {bool} isOpen open state - likely from useToggle in parent component
 * @param {function} close toggle function for open state - likely from useToggle in parent component
 */

const TrackActionMenu = ({
  track,
  showCompile,
  // action menu props
  isOpen,
  close,
  closeOnNavigation,
  // redux-connect
  user,
  canDownload,
  isPromo,
  isTrackPageActive
}) => {
  // Get DSP info for track
  //TODO: move to tracksTable once GQL is suppoted
  const [{ data, fetching, error }] = useQuery({
    query: ReleaseDspQuery,
    variables: { id: track.release.id },
    skip: !track.release.id && !isOpen
  });
  const dspLinks = fetching || error ? null : releaseStoreInfoButtonsTransform(data);

  const trackActions = getTrackActions({
    track,
    user,
    isTrackPageActive,
    dspLinks
  });

  return (
    <>
      <ActionMenu isOpen={isOpen} close={close} closeOnNavigation={closeOnNavigation}>
        <TrackMenuHeader track={track} showCompile={showCompile} isPromo={isPromo} />

        {isPromo && (
          <Container>
            <PromoReactionWidget track={track} close={close} />
          </Container>
        )}

        <ActionMenuList actions={trackActions} closeMenu={close}>
          {canDownload && !isPromo && (
            <DownloadTrackActionItem track={track} closeParentMenu={close} />
          )}
        </ActionMenuList>
      </ActionMenu>
    </>
  );
};

TrackActionMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  closeOnNavigation: PropTypes.bool,
  showCompile: PropTypes.bool,
  track: PropTypes.shape(),
  // redux-connect
  user: PropTypes.shape(),
  isPromo: PropTypes.bool,
  canDownload: PropTypes.bool,
  isTrackPageActive: PropTypes.bool
};

export default connect((state, { track }) => ({
  user: selectUser(state),
  isPromo: selectIsTrackOnPromo(state, { audio: track }),
  canDownload: selectIsTrackDownloadable(state, { audio: track }),
  isTrackPageActive: selectIsFeatureActive(state, TRACK_PAGE)
}))(TrackActionMenu);
