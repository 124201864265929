import { BREAKPOINTS } from '../config/constants';

// NOTE: we referenced BS4 for device sizing
// ref: https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints

// Offset used to prevent issue of both responsive queries being met at same screen size
const MEDIA_OFFSET = 0.02;

// -- Simple js size helpers --
export const isMobile = (screenWidth?: number) =>
  (screenWidth || window.innerWidth) < BREAKPOINTS.SM - MEDIA_OFFSET;
