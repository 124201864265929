import PropTypes from 'prop-types';
import React from 'react';
import * as Scroll from 'react-scroll';
import { NavLink } from 'react-router-dom';

const isActive = (linkName, activeNav) => {
  if (linkName === activeNav) return 'active';
  return '';
};

const SideNavItem = ({
  to,
  isExternal,
  offset = -60,
  children,
  manualSpy = false,
  handleNavClick,
  activeNav,
  disabled,
  scrollSpy = false,
  'data-testid': testId
}) => (
  <li
    className={`SideNav__item ${disabled ? 'SideNav__disabled' : ''}`}
    data-testid={testId || `SideNavItem-${to}`}
    disabled={disabled}
  >
    {scrollSpy && (
      <Scroll.Link
        activeClass={manualSpy ? '' : 'active'}
        className={`${manualSpy && isActive(to, activeNav)}`}
        duration={500}
        offset={offset}
        spy={!manualSpy}
        hashSpy={!manualSpy}
        smooth
        to={to}
        onClick={() => {
          if (disabled) return;
          if (manualSpy) handleNavClick(to);
        }}
      >
        {children}
      </Scroll.Link>
    )}

    {!scrollSpy && !isExternal && (
      <NavLink activeclass="active" to={to}>
        {children}
      </NavLink>
    )}

    {!scrollSpy && isExternal && (
      <a href={to} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )}
  </li>
);

SideNavItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  offset: PropTypes.number,
  'data-testid': PropTypes.string,
  // opt-out for spies.  Manually set links active on click
  manualSpy: PropTypes.bool,
  handleNavClick: PropTypes.func,
  // name of active link
  activeNav: PropTypes.string,
  disabled: PropTypes.bool,
  scrollSpy: PropTypes.bool
};

export default SideNavItem;
