import PropTypes from 'prop-types';
import React from 'react';
import _kebabCase from 'lodash/kebabCase';

import FormGroup from 'components/Form/FormGroup';

/**
 * [MobileNav] - Replacement for SideNav component on Desktop
 *
 * @param {object[]} routes
 * @param {string} routes[].name - printed name of route displayed in clickable row / link
 * @param {string} routes[].to - url to navigate to
 * @param {func} routes[].icon - render prop for icon
 */

const MobileNav = ({ routes, className }) => (
  <FormGroup className={className}>
    <FormGroup.List className="pt-0 pb-0">
      {routes.map(({ name, icon: Icon, to, isHidden, isDisabled }, index) => {
        if (isHidden) return null;

        return (
          <FormGroup.Row
            key={name}
            title={name}
            renderIcon={Icon ? props => <Icon {...props} /> : undefined}
            to={to}
            disabled={isDisabled}
            data-testid={MobileNav.TEST_IDS.getRowTestId(name)}
          />
        );
      })}
    </FormGroup.List>
  </FormGroup>
);

MobileNav.propTypes = {
  className: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.shape())
};

MobileNav.TEST_IDS = {
  getRowTestId: name => `MobileNav-row-${_kebabCase(name)}`
};

export default MobileNav;
