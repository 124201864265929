import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import PlayerButton, { StaticButton } from 'components/PlayerButton';
import Screen from 'components/Screen';
import { AUDIO_TYPES, isProduction } from 'config/constants';
import { isFallbackFlyer } from 'helpers/shows';
import userAgent from 'helpers/userAgent';
import useProtonPlayer from 'hooks/useProtonPlayer';
import { selectIsTrackOnPromo } from 'redux/selectors/promoReleases';

import PlayerContainerTombstone from './PlayerContainerTombstone';
import PlayerGetFullTrackButton from './PlayerGetFullTrackButton';
import PlayerMeta from './PlayerMeta';
import PlayerReactDownloadButton from './PlayerReactDownloadButton';
import PlayerTracklist from './PlayerTracklist';
import TracklistTrigger from './PlayerTracklist/TracklistTrigger';
import PlayerVolume from './PlayerVolume';
import PlayerWaveformAnimated from './PlayerWaveformAnimated';
import PlayerWaveformStatic from './PlayerWaveformStatic';

const PlayerContainer = ({ location }) => {
  const currentTimeInSeconds = format(new Date(), 't');
  // Add check for production as the staging API's time isn't accurate.

  const [isTracklistVisible, setIsTracklistVisible] = useState(false);
  const player = useProtonPlayer();
  const playing = player.currentTrack;

  const isPromo = useSelector(state => selectIsTrackOnPromo(state, { audio: playing }));

  const isRadioShow = playing?.type === AUDIO_TYPES.RADIO;
  const expiredRadioShow =
    isRadioShow && isProduction && currentTimeInSeconds > playing?.end_time;

  if (!playing || !playing.id || expiredRadioShow) {
    return <PlayerContainerTombstone />;
  }

  const tracklist =
    playing.type === 'track' ? [] : playing.tracklist || playing.algolia_tracklist;

  return (
    <div className="Player" data-testid="Player">
      <div className="Player__content">
        <div className="Player__button-container">
          <StaticButton />
        </div>

        <PlayerContainer.ImageTemplate playing={playing} />

        <PlayerMeta
          playing={playing}
          playerType={playing.type}
          isPreview={player.isPreview}
        />

        {(playing.type === AUDIO_TYPES.MIX || playing.type === AUDIO_TYPES.TRACK) && (
          <PlayerWaveformStatic />
        )}

        {playing.type === AUDIO_TYPES.RADIO &&
          !userAgent.isMobile &&
          !userAgent.isTablet && <PlayerWaveformAnimated />}

        {playing.type === AUDIO_TYPES.TRACK ? (
          <Screen.MEDIUM up>
            {isPromo ? (
              <PlayerReactDownloadButton track={playing} />
            ) : (
              <PlayerGetFullTrackButton track={playing} />
            )}
          </Screen.MEDIUM>
        ) : (
          <TracklistTrigger
            onClick={setIsTracklistVisible}
            tracklistCount={tracklist.length}
            toggleTracklistVisibility={() => setIsTracklistVisible(!isTracklistVisible)}
          />
        )}

        <div className="Player__volume-trigger-container hidden-xs hidden-sm">
          <PlayerVolume />
        </div>
      </div>

      {Boolean(tracklist.length) && (
        <PlayerTracklist
          playing={playing}
          setTracklistVisibility={setIsTracklistVisible}
          tracklistVisibility={isTracklistVisible}
          location={location}
        />
      )}
    </div>
  );
};

PlayerContainer.SELECTORS = {
  CONTAINER: '[data-testid=Player]',
  META: PlayerMeta.SELECTORS,
  PLAYER_BUTTON: PlayerButton.SELECTORS
};

PlayerContainer.propTypes = {
  // withRouter
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

PlayerContainer.ImageTemplate = ({ playing }) => {
  const { type } = playing;

  switch (type) {
    case AUDIO_TYPES.SHOWSET:
    case AUDIO_TYPES.MIX:
      return !isFallbackFlyer(playing.show_image) ? (
        <div className="Player__banner-outer">
          <div className="Player__banner-inner">
            <img src={playing.show_image} className="banner" alt="Show flyer" />
          </div>
        </div>
      ) : null;

    case AUDIO_TYPES.TRACK: {
      const { release } = playing;
      const imgUrl = release.cover_art.thumb.url;

      return (
        imgUrl && (
          <div className="Player__banner-outer">
            <div className="Player__banner-inner">
              <Link to={release.slug}>
                <img
                  src={release.cover_art.thumb.url}
                  alt={`Cover art for ${release.name}`}
                />
              </Link>
            </div>
          </div>
        )
      );
    }

    default:
      return null;
  }
};

PlayerContainer.ImageTemplate.propTypes = {
  playing: PropTypes.shape()
};

export default withRouter(PlayerContainer);
