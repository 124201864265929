import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Button from 'components/Button';
import Modal from 'components/Modal';
import TextEmphasis from 'components/TextEmphasis';
import useMutationWithAlert from 'hooks/useMutationWithAlert';

const SendVerificationMutation = `
  mutation RequestMagicLink ($redirectUrl: String, $token: String!) {
    requestMagicLink (input: { redirectUrl: $redirectUrl, token: $token }) {
      errorDetails {
        message
        path
      }
    }
  }
`;

/**
 * [SendMagicLinkModal]
 *
 * Props:
 * @prop `close` - Callback to close the modal.
 * @prop `requestMagicLinkToken` - Token used to request a magic link. When the client throws a 403
 * 'MFA Required' error, the server will include a token that can be used to request a magic link for the
 * requesting user.
 * @prop `expiredToken` - If the token is expired, we want to show a different message to the user.
 */

const SendMagicLinkModal = ({
  close,
  requestMagicLinkToken,
  expiredToken
}: {
  close: () => void;
  requestMagicLinkToken: string;
  expiredToken?: boolean;
}) => {
  const [allowResendVerification, setAllowResendVerification] = useState(true);

  const { search } = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const redirectUrl = urlParams.get('redirectUrl');

  const [
    { data, fetching: isSubmitting, error: networkError, dataErrors },
    sendVerificationMutation
  ] = useMutationWithAlert(SendVerificationMutation);

  const error = !!(dataErrors || networkError);

  const sendVerification = () => {
    const currentUrl = window.location.origin + window.location.pathname;
    const cleanParams = new URLSearchParams(window.location.search);
    cleanParams.delete('verificationToken');
    cleanParams.delete('redirectUrl');
    const cleanRedirectUrl = currentUrl + '?' + cleanParams.toString();

    return sendVerificationMutation({
      token: requestMagicLinkToken,
      // If we are resending (expired token) then there is possibly already a redirectUrl in the url params
      redirectUrl: redirectUrl || cleanRedirectUrl
    }).then(() => {
      setAllowResendVerification(false);
      setTimeout(() => setAllowResendVerification(true), 10000);
    });
  };

  const success = !!data && !error;
  const verificationState = success ? 'success' : expiredToken ? 'error' : 'default';

  return (
    <Modal
      title={MODAL_TEXT[verificationState].title}
      subtitle={MODAL_TEXT[verificationState].subtitle}
      onClose={close}
      frozen={isSubmitting}
    >
      <Modal.ButtonGroup>
        <Button color={Button.COLORS.NEUTRAL} disabled={isSubmitting} onClick={close}>
          Close
        </Button>

        {allowResendVerification && (
          <Button
            data-testid={TEST_IDS.SEND_VERIFICATION_EMAIL}
            onClick={() => sendVerification()}
            disabled={isSubmitting}
          >
            {isSubmitting
              ? 'Sending Verification...'
              : MODAL_TEXT[verificationState].submitText}
          </Button>
        )}
      </Modal.ButtonGroup>
    </Modal>
  );
};

export const TEST_IDS = {
  SEND_VERIFICATION_EMAIL: 'send-verification-email',
  VERIFICATION_REQUIRED: 'verification-required',
  VERIFICATION_SENT: 'verification-sent',
  VERIFICATION_ERROR: 'verification-error'
};

const MODAL_TEXT = {
  error: {
    title: 'Verification Error',
    testId: TEST_IDS.VERIFICATION_ERROR,
    subtitle:
      'Sorry, verification links expire after 20 minutes. Request a new one below?',
    submitText: 'Resend Verification Email'
  },
  success: {
    title: 'Verification Sent!',
    testId: TEST_IDS.VERIFICATION_SENT,
    subtitle:
      'We sent you a verification email. Please check your email and follow the instructions.',
    submitText: 'Resend Verification Email'
  },
  default: {
    title: 'Verification Required',
    testId: TEST_IDS.VERIFICATION_REQUIRED,
    subtitle: (
      <>
        For security reasons, we need to verify your identity before you can continue.
        Click <TextEmphasis>Send Verification Email</TextEmphasis> and we&apos;ll email
        you a link to verify your identity.
      </>
    ),
    submitText: 'Send Verification Email'
  }
} as const;

export default SendMagicLinkModal;
