import ahoy from 'ahoy.js';
import useProtonKeyboardShortcuts from './useProtonKeyboardShortcuts';
import useDetectTouchDevice from './useDetectTouchDevice';
import useAutoMasquerade from './useAutoMasquerade';
import useUserDataPolling from './useUserDataPolling';
import useAcknowledgements from './useAcknowledgements';
import useFetchFollowedEntities from './useFollowedEntities';
import useHiringConsolePost from './useHiringConsolePost';
import useIntercom from './useIntercom';
import usePreventInputZoomOnIOS from './usePreventInputZoomOnIOS';
import useBugSnag from './useBugSnag';
import usePrivacyPolicyConsent from './usePrivacyPolicyConsent';
import useFingerprint from 'hooks/useFingerprint';
import { getCookie } from 'react-use-cookie';
import { getCurrentAuthCookies } from 'helpers';
import { FINGERPRINT_COOKIE } from 'config/constants';

/**
 * [useInitializeSideEffects] - initializes side effects that should run at app startup
 */

function useInitializeSideEffects() {
  // Accessibility / Screen / Keyboard
  useProtonKeyboardShortcuts();
  usePreventInputZoomOnIOS();
  useDetectTouchDevice();

  // Data fetching
  useAutoMasquerade();
  useUserDataPolling();
  useAcknowledgements();
  useFetchFollowedEntities();
  usePrivacyPolicyConsent();

  // 3rd party services
  useFingerprint();
  useIntercom();
  useBugSnag();

  const ahoyHeaders: Record<string, string> = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    'x-fingerprint-visitor-id': getCookie(FINGERPRINT_COOKIE)
  };
  const { jwt } = getCurrentAuthCookies();
  if (jwt) {
    ahoyHeaders['Authorization'] = `Bearer ${jwt}`;
  }

  ahoy.configure({
    urlPrefix: process.env.REACT_APP_RAILS_API_BASE,
    useBeacon: false,
    cookieDomain: 'protonradio.com',
    headers: ahoyHeaders
  });

  // Other
  useHiringConsolePost();
}

export default useInitializeSideEffects;
