import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS } from 'config/constants';
import { isUrlExternal } from 'config/routes';

export const EmphasizedText = styled.span<{ $color?: string }>`
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 15%,
    rgba(255, 113, 52, 0.5) 15%,
    rgba(255, 113, 52, 0.5) 35%,
    transparent 35%,
    transparent 100%
  );
  color: ${({ $color }) => $color || `#000`};
  font-style: normal;
  font-weight: bold;
  padding: 0 0.05rem;
  ${({ onClick }) =>
    onClick &&
    `
    background-image: none;
    color: ${COLORS.PRIMARY};
    cursor: pointer;
    font-weight: inherit;
  `}
  a:hover {
    text-decoration: none;
    color: ${COLORS.PRIMARY};
  }
`;

/**
 * [TextEmphasis] - adds formatting to draw users attention.  Typically used to highglight an entity name.  Currently,
 * the styling for this adds an underline to the passed text
 */

interface Props {
  color?: string;
  to?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const TextEmphasis = ({ color, to, onClick, children }: Props) => {
  if (to) {
    const isExternal = isUrlExternal(to);
    return (
      <EmphasizedText $color={color}>
        {isExternal ? (
          <a href={to}>{children}</a>
        ) : (
          <Link to={to}>
            <>{children}</>
          </Link>
        )}
      </EmphasizedText>
    );
  }

  return (
    <EmphasizedText onClick={onClick} $color={color}>
      {children}
    </EmphasizedText>
  );
};

export default TextEmphasis;
