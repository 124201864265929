import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectIsTrackOnPreview } from 'redux/selectors';

import AudioPlayer from './useAudioPlayer/AudioPlayer';

export const player = new AudioPlayer();
let playerID = Date.now();

const useProtonPlayer = () => {
  const [playerState, setPlayerState] = useState(player.state);
  const [currentTrack, setCurrentTrack] = useState(player.currentTrack());
  const [volume, setVolume] = useState(0.7);

  useEffect(() => {
    const handleStateChange = ({ track, state, volume }) => {
      setPlayerState(state);
      setCurrentTrack(track);
      setVolume(volume);
    };

    player.on('STATE_CHANGED', handleStateChange);

    return () => {
      player.off('STATE_CHANGED', handleStateChange);
    };
  }, []);

  const isPreview = useSelector(state => {
    const audio = currentTrack?.meta || currentTrack;
    if (!audio || audio.type !== 'track') return false;
    return selectIsTrackOnPreview(state, { audio });
  });

  const send = (eventName, payload) => {
    console.log(`PlayerHook:${eventName}`, payload);
    player.send(eventName, payload);
  };

  return {
    id: playerID,
    type: currentTrack?.meta?.type || 'radio',
    volume,
    state: playerState,
    currentTrack: currentTrack
      ? {
          ...(currentTrack?.meta || currentTrack),
          isPreview
        }
      : null,
    playlist: player.queue,
    send,
    player,
    isPreview
  };
};

// This hook updates every time the player "ticks", aka the regular interval
// used to update the internal playhead position. It should be used for
// syncing playhead UI and state instead of a polling-based `setInterval`.
export const usePlayheadPosition = f => {
  useEffect(() => {
    f(player.currentTime());

    player.on('TICK', f);

    return () => {
      player.off('TICK', f);
    };
  });
};

export default useProtonPlayer;
