import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ARTIST_ROLES, SMALL_BREAK_POINT } from 'config/constants';
import { TRACK_PAGE } from 'config/features/featureFlags';

import { getTrackArtistsByRole, addMatchingArtistLinksToString } from 'helpers';

import { selectIsFeatureActive } from 'redux/selectors';

const StyledContainer = styled.div`
  a {
    position: relative;

    &:after {
      background: ${({ $highlightColor }) => $highlightColor || '#666'};
      bottom: 0.1rem;
      content: '';
      display: none;
      height: 0.1rem;
      left: 0;
      opacity: 0.35;
      position: absolute;
      width: 100%;
    }

    &:hover {
      text-decoration: none !important;

      &:after {
        display: block;
        opacity: 0.8;
      }
    }
  }

  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${SMALL_BREAK_POINT}px) {
    a:after {
      display: none;
    }
  }
`;

const StyledRemixTitleWithLink = styled.span`
  a {
    &:after {
      display: block;
    }
  }
`;

const StyledRemixTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TrackTitleWithRemixers = ({
  playing,
  noLink,
  highlightColor,
  isTrackPageActive,
  ...rest
}) => {
  const remixArtists = getTrackArtistsByRole(playing.artists, ARTIST_ROLES.REMIX);

  if (noLink) {
    return (
      <StyledRemixTitle {...rest}>
        {playing.title}
        {playing.version && <> ({playing.version})</>}
      </StyledRemixTitle>
    );
  }

  return (
    <StyledContainer $highlightColor={highlightColor} {...rest}>
      {!isTrackPageActive ? (
        playing.title
      ) : (
        <Link to={playing.slug}>{playing.title}</Link>
      )}
      {playing.version && (
        <span>
          {' '}
          <StyledRemixTitleWithLink>
            ({addMatchingArtistLinksToString(playing.version, remixArtists)})
          </StyledRemixTitleWithLink>
        </span>
      )}
    </StyledContainer>
  );
};

TrackTitleWithRemixers.propTypes = {
  playing: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    version: PropTypes.string
  }),
  noLink: PropTypes.bool,
  highlightColor: PropTypes.string,
  isTrackPageActive: PropTypes.bool
};

export default connect(
  state => ({
    isTrackPageActive: selectIsFeatureActive(state, TRACK_PAGE)
  }),
  {}
)(TrackTitleWithRemixers);
