import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/selectors';
import { matchPath, useLocation } from 'react-router-dom';

import {
  initializeIntercom,
  shutdownIntercom,
  hideIntercomLauncher,
  showIntercomLauncher
} from 'config/intercom';
import routeMap from 'config/routes';
import { isSignedIn, isProUser } from 'helpers/user';
import useBreakpoint from 'hooks/useBreakpoint';
import { BREAKPOINTS } from 'config/constants';

// NOTE: for now we only allow  on specific  pages but that might change in the future
const ALLOWED_PATHS = [
  { path: routeMap.artistProfileWizard.base, exact: false },
  { path: routeMap.inbox.root + routeMap.inbox.welcome },
  { path: routeMap.users.root + routeMap.users.verification },
  { path: routeMap.account.root + routeMap.account.payment }
];

function useIntercom() {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();

  const shouldLoadIntercom = ALLOWED_PATHS.some(({ path, exact, strict }) =>
    matchPath(pathname, {
      path,
      exact,
      strict
    })
  );

  const isSmallScreen = useBreakpoint(BREAKPOINTS.SMALL, useBreakpoint.DIRECTIONS.DOWN);

  useEffect(() => {
    if (!isSignedIn(user)) {
      shutdownIntercom();
    } else if (isProUser(user)) {
      initializeIntercom(user, isSmallScreen);
    }
  }, [user, isSmallScreen]);

  useEffect(() => {
    if (shouldLoadIntercom && !isSmallScreen) {
      showIntercomLauncher();
    } else {
      hideIntercomLauncher();
    }
  }, [shouldLoadIntercom]);
}

export default useIntercom;
