import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { scrollSpy as reactScrollSpy, scroller } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _kebabCase from 'lodash/kebabCase';

import SideNavItem from './SideNavItem';

const IconWrapper = styled.div`
  margin-right: 0.75rem;
  max-height: 2rem;
  min-width: 1.8rem;
  text-align: center;
`;

const SideNav = ({ location, children, routes, scrollSpy = false }) => {
  const [activeNav, setActiveNav] = useState(null);

  useEffect(() => {
    if (!scrollSpy) return;

    const duration = 250;
    const hash = location.hash.substr(1);
    const delay = 100;

    // handle scrolling to hash if included when navigating to page
    if (hash) {
      scroller.scrollTo(hash, {
        duration,
        delay,
        smooth: true
      });

      // scroll to hash and update scroll spy
      setTimeout(() => {
        reactScrollSpy.update();
      }, duration + delay + 500);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash, scrollSpy]);

  return (
    <ul className="SideNav__container">
      {children
        ? React.Children.map(children, child =>
            React.cloneElement(child, {
              activeNav,
              handleNavClick: to => setActiveNav(to),
              scrollSpy
            })
          )
        : routes.map(route => {
            if (route.isHidden) return null;

            return (
              <SideNavItem
                key={route.name}
                to={route.to}
                isExternal={route.isExternal}
                disabled={route.isDisabled}
                scrollSpy={scrollSpy}
                data-testid={`SideNavItem-${_kebabCase(route.name)}`}
              >
                {route.icon && (
                  <IconWrapper>
                    <route.icon width={18} color="currentColor" />
                  </IconWrapper>
                )}
                {route.name}
              </SideNavItem>
            );
          })}
    </ul>
  );
};

SideNav.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string
  }),
  children: PropTypes.node,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      to: PropTypes.string,
      icon: PropTypes.func,
      isHidden: PropTypes.bool,
      isDisabled: PropTypes.bool
    })
  ),
  scrollSpy: PropTypes.bool
};

SideNav.TEST_IDS = {
  getNavItemId: name => `SideNavItem-${_kebabCase(name)}`
};

export default withRouter(SideNav);
