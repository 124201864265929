import { isAdminUser } from 'helpers/user';

// Define search categories and the order for results to be displayed
const SEARCH_CATEGORIES = ['genres', 'artists', 'labels', 'shows', 'tracklists'];
const ADMIN_SEARCH_CATEGORIES = [...SEARCH_CATEGORIES, 'users', 'userLookup'];

export const getOrderedResultCategories = user => {
  if (isAdminUser(user)) return ADMIN_SEARCH_CATEGORIES;
  return SEARCH_CATEGORIES;
};
